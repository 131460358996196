import './NegotiationItem.scss';
import { useState } from "react";
import styles from '../../styles/global.scss';
// MUI components
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function NegotiationItem(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
        
    function getAgreementText(row){
        if(!row.agreement){
            return 'no agreement';
        } else {
            if(row.peace_agreement){
            return 'peace agreement';
            } else {
            return 'ceasefire';
            }
        }
    }

    function getDateString(year, month){
        var date = new Date(year+'-1'); // to prevent false parsing, month will anyway not be displayed when excluded from options
        var options = { year: 'numeric' };
        if(month !== 0){
            // if there is an entry for the month in the data, use it for display
            date = new Date(year+'-'+month)
            options = { year: 'numeric', month: 'long' };
        }
        const formatted = date.toLocaleDateString("en-GB", options)
        return formatted
    }
    
    return (
        <List
        sx={{ width: '100%' }}
        component="nav"
        className='negotiation-list'
        disablePadding
        >
            <ListItemButton onClick={handleClick}
            className='negotiation-element'
            sx={{
                '& .MuiSvgIcon-root': {
                    color: styles.textMedium
                }
            }}
            >
                <div className='negotiation-item'>
                    <Typography
                    fontSize={styles.fontXXSmall}
                    >
                        {props.item.negotiation_id}
                    </Typography>
                    <Typography
                    fontSize={styles.fontXXSmall}
                    >
                        {getDateString(props.item.start_year, props.item.start_month)}
                    </Typography>
                    <Typography
                    fontSize={styles.fontXXSmall}
                    >
                        {getDateString(props.item.end_year, props.item.end_month)}
                    </Typography>
                    <Typography
                    fontSize={styles.fontXXSmall}
                    >
                        {props.item.location_negotiations}
                    </Typography>
                    <Typography
                    fontSize={styles.fontXXSmall}
                    >
                        {props.item.mediated_negotiations? 'yes': 'no'}
                    </Typography>
                    <Typography
                    fontSize={styles.fontXXSmall}
                    >
                        {getAgreementText(props.item)}
                    </Typography>
                </div>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className='negotiation-item-detail'>
                    {(props.item.third_party.length > 0)? 
                    <Typography 
                    pb={1}
                    fontSize={styles.fontXXSmall}
                    sx={{
                        maxWidth: styles.maxWidthText,
                        minWidth: styles.maxWidthText,
                    }}
                    >{'Third Party: ' + props.item.third_party}</Typography>
                    : null}
                    <Typography
                    sx={{
                        maxWidth: styles.maxWidthText,
                    }}
                    fontSize={styles.fontXXSmall}
                    >{props.item.description}</Typography>
                </div>
            </Collapse>
        </List>
    );
}