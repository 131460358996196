
const DEV_URL = 'http://127.0.0.1:8000'; // point to locally hosted application
const PROD_URL = 'https://peace-observatory-backend-9yccq.ondigitalocean.app'; // point to server hosted application

export const BASE_URL = PROD_URL; 
// TODO: Change to development URL if you want to serve the backend locally

export var hasFetchError = false;

function handleFetchError(error){
    console.log('error', error)
    hasFetchError = true
}

export const getCurrentEventDataYear = async (params) => {
    /**
     * Reqest the current year for which event data is displayed.
     * Parameters: None
     */
    const requestURL = `${BASE_URL}/get-events-year${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getDyadOptions = async (params) => {
  /**
   * Reqest information on the available dyads.
   * Parameters: None
   */
  const requestURL = `${BASE_URL}/get-dyad-options${params}`;
  const data = await fetch(requestURL,
      {
          method: 'GET'
      }
  ).then(response => response.json(),
  (error) => {
      if (error) {
        // handle error here
        handleFetchError(error)
      }
    });

  return data;
}

export const getEventsFatalities = async (params) => {
    /**
     * Reqest data used for the initial conflict data map colouring.
     * Parameters: None
     */
    const requestURL = `${BASE_URL}/get-events-fatalities${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getNegotiationsAgreements = async (params) => {
    /**
     * Reqest data used for the initial peace data map colouring.
     * Parameters: None
     */
    const requestURL = `${BASE_URL}/get-negotiations-agreements${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getCountryStatistics = async (params) => {
    /**
     * Reqest summary data for a given dyad, including the max and sum numbers of negotiations/agreements/fatalities .
     * Parameters: gw_number (int) -> The Gleditsch + Ward number of the relevant country.
     */
    const requestURL = `${BASE_URL}/get-country-statistics${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getAvailableDownloads = async (params) => {
    /**
     * Get information on the files available for download.
     * Parameters: None
     */
    const requestURL = `${BASE_URL}/get-available-downloads${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getAvailableSummaries = async (params) => {
    /**
     * Get information on the summaries files available for download.
     * Parameters: None
     */
    const requestURL = `${BASE_URL}/get-available-summaries${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getFileForDyadId = async (params) => {
  /**
   * Get information on the summary file available for download for given dyad id.
   * Parameters: dyad_id(int) -> id of dyad for which file should be downloadable
   */
  const requestURL = `${BASE_URL}/get-summary-for-dyad${params}`;
  const data = await fetch(requestURL,
      {
          method: 'GET'
      }
  ).then(response => response.json(),
  (error) => {
      if (error) {
        // handle error here
        handleFetchError(error)
      }
    });

  return data;
}

export const downloadFile = async (params) => {
    /**
     * Download a file from the server.
     * Parameters: filename(str) -> name of the file which should be downloaded
     */
    const requestURL = `${BASE_URL}/download-file${params}`;
    const filename = params.split('=')[2]
    await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => {
        return response.blob()
    },(error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      }).then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
     }
    );
}

export const downloadMultipleFiles = async (params) => {
    /**
     * Download multiple files as zip from the server.
     * Parameters: filenames(list(str)) -> name of all files which should be downloaded
     */
    const requestURL = `${BASE_URL}/download-multiple${params}`;

    const filename = 'dyad_backgrounds';
    await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => {
        return response.blob()
    },(error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      }).then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
     }
    );
}

export const getCountryTimeline = async (params) => {
    /**
     * Reqest negotiations/agreements/fatalities for a given country, summed for each year.
     * Parameters: gw_number (int) -> The Gleditsch + Ward number of the relevant country.
     */
    const requestURL = `${BASE_URL}/get-country-timeline${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getDyadText = async (params) => {
    /**
     * For a given dyad-id get the corresponding summary text.
     * Parameters: dyad_id(int) -> id of dyad of interest
     */
    const requestURL = `${BASE_URL}/get-dyad-text${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getPeaceData = async (params) => {
    /**
     * For a given dyad-id get all corresponding rounds of negotiations.
     * Parameters: dyad_id(int) -> id of dyad of interest
     */
    const requestURL = `${BASE_URL}/get-peace-data${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getDyads = async (params) => {
    /**
     * Get information (name, id) on all dyads featured in the APP data for a country, grouped by their category.
     * Parameters: gw_number (int) -> The Gleditsch + Ward number of the relevant country.
     */
    const requestURL = `${BASE_URL}/get-dyads${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}