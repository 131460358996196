import './CountryView.scss';
import styles from '../../styles/global.scss';
import { useState, useEffect } from "react";
// API functions
import { getCountryStatistics } from '../../backend/queryBackend';
// MUI components
import Typography from '@mui/material/Typography';
// components
import DyadCategory from '../DyadCategory/DyadCategory';
import DyadTimeline from '../DyadTimeline/DyadTimeline'; 
import NumberDisplay from '../NumberDisplay/NumberDisplay';
import BackButton from '../BackButton/BackButton';

export default function CountryView(props) {
    const [countryStats, setCountryStats]  = useState({})
    const [needFetching, setNeedFetching] = useState(false);

    useEffect(() => {
        // prevents API call from being executed twice
        if (!props.selectedCountry['id'] || needFetching) return;
        setNeedFetching(true);
      }, [props.selectedCountry['id']]);

    useEffect(( )=> {
        if(!needFetching) return;

        if(props.selectedCountry['id']){
            getCountryStatistics(`?gw_number=${props.selectedCountry['id']}`).then(responseData => {
                setCountryStats(responseData)
            })
        } 
    }, [needFetching])

    function resetSearch(){
        // unset the selected country when clicking on the back button
        props.setSelectedCountry(null)
    }

    return (
        <div className="country-view">
            <div className='country-header'>
                <BackButton 
                className='button-wrapper' 
                searchParamName={props.searchParamName}
                resetSearch={resetSearch}
                buttonText={'world view'}></BackButton>
                <Typography
                className='title'
                color={styles.textMedium}
                fontSize={styles.fontMedium}
                >{props.selectedCountry['name']}</Typography>
                <div className="right"></div>
            </div>
            <Typography
                className='title'
                color={styles.textMedium}
                fontSize={styles.fontSmall}
                >Dyads</Typography>
            <div className='country-content'>
                <div className='dyad-list'>
                    <div className='dyad-list-wrapper'>
                        {props.dyads?.map(dyad => (
                            <DyadCategory 
                            key={'dyad-cat-' + dyad.dyadCategory}
                            category={dyad.dyadCategory}
                            items={dyad.dyadItems}
                            selectDyad={props.selectDyad}
                            ></DyadCategory>
                        ))}
                    </div>
                </div>
                <div className='numbers-display'>
                    <div className='total-numbers'>
                        <NumberDisplay
                        amount={countryStats.sumNegotiations}
                        subheader={'rounds of'}
                        name={'negotiations'}
                        />
                        <NumberDisplay
                        amount={countryStats.sumAgreements}
                        subheader={'number of'}
                        name={'agreements'}
                        />
                        <NumberDisplay
                        amount={countryStats.sumFatalities}
                        subheader={'recorded'}
                        name={'fatalities'}
                        />
                    </div>
                    <div className='timeline-numbers'>
                        <DyadTimeline
                        countryStats={countryStats}
                        gw_number={props.selectedCountry['id']}
                        ></DyadTimeline>
                    </div>
                </div>
            </div>
        </div>
    );
}