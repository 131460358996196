import './AboutView.scss';
import styles from '../../styles/global.scss';
import { Link } from "react-router-dom";
// MUI components
import Typography from '@mui/material/Typography';

export default function AboutView(props) {
  return (
  <div className="about-view-wrapper">
    <div className='about-view'>
      <div className='about-view-elem'>
      <Typography 
      my={2} 
      color={styles.textLight}
      fontSize={styles.fontSmall}
      sx={{textAlign:'center'}}
      >
        Welcome to the Peace Observatory!
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        Our mission is to facilitate analysis of peace processes in armed conflicts by providing accessible information and data.
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        Explore the <Typography fontWeight='bold' component={Link} to={'/download'} >Peace Observatory Dataset</Typography>, a comprehensive collection covering peace negotiations in armed conflicts identified by the <Typography fontWeight='bold' component={Link} to={'https://ucdp.uu.se'} target="_blank" rel="noopener noreferrer">Uppsala Conflict Data Program (UCDP)</Typography>. 
        We gather information from news media, international organizations, academic publications, memoirs, and minutes of negotiations to ensure comprehensive and reliable data.
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        Transparency is at the core of our work at the Peace Observatory. 
        That is why we provide analytical summaries of all the rounds of negotiations identified in the Peace Observatory Dataset and make sure to disclose our sources. 
        You can discover the summaries on <Typography fontWeight='bold' component={Link} to={'/'}>this webpage</Typography>. 
        We also hope that these summaries will help researchers gain insights, while policymakers and stakeholders get essential context for informed decision-making.
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        Additionally, our <Typography fontWeight='bold' component={Link} to={'/visualisations'}>visualization tool</Typography> empowers you to create your own visualizations based on the Peace Observatory Dataset. 
        Generate compelling insights and discover meaningful patterns about peace processes.
      </Typography>
      <Typography 
      mb={2} 
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        To get a good understanding of the conflict patterns, check out our <Typography fontWeight='bold' component={Link} to={'/conflict-networks'}>interactive application</Typography> to visualize armed conflicts identified by the UCDP. 
        Plot events from the UCDP on a map and explore conflictual and cooperative relationships between different actors in conflict networks.
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        Beyond data and analysis, do not miss <Typography fontWeight='bold' component={Link} to={'/podcasts'}>"Peace Puzzles"</Typography>, our podcast shedding light on the complexities of peacemaking. 
        Tune in to hear stories from those involved in ending armed conflicts, both historical and contemporary.
      </Typography>
      <Typography 
      mb={2} 
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        Meet the team behind the Peace Observatory: Allard Duursma, Executive Director; Marine Epiney, Project Manager and Research Analyst; Samantha Gamez and Orestes Leventis, Research Analysts; and Diana Steffen, Web Developer. 
        Past and current Student Assistants include: Leo Aschwanden, Carina Diener, Amanda Gach, Sophie Kalf, Kristina Koch, Johannes Lucht, Jelena Mihajlovic, Chatrina Schumacher, Mira Stocker, and Marc Werner.
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        We are very grateful for financial support by the Center for Security Studies (CSS), the Folke Bernadotte Academy, and the Swiss National Science Foundation (SNSF).
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        We appreciate your feedback and questions. Reach us at <a className='bold' href="mailto:info@peaceobservatory.com">info@peaceobservatory.com</a>. 
        Help us enhance the quality of the Peace Observatory.
      </Typography>
      <Typography 
      mb={2}
      color={styles.textLight}
      fontSize={styles.fontText}
      >
        We sincerely appreciate your support in our endeavor to enable analysis of peace negotiations. 
        Together, we can pave a well-informed path towards peace.
      </Typography>
      </div>
    </div>
  </div>
  );
}