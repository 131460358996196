import './ReducedDownloadItem.scss';
import styles from '../../styles/global.scss';
// API functions
import { downloadFile } from '../../backend/queryBackend';
// MUI components
import DownloadIcon from '@mui/icons-material/Download';

export default function ReducedDownloadItem(props) {

    function handleDownload(folder, filename, filetype){
        // forward the request for downloading a file to the API
        const name = filename + '.' + filetype
        downloadFile(`?folder=${folder}&filename=${name}`)
    }

    return (
        <div className="reduced-download-item" 
        style={{cursor:'pointer'}}
        onClick={() => handleDownload(props.folder, props.item.filename, props.item.filetype)}>
            <DownloadIcon
            color="secondary"
            sx={{ fontSize: styles.fontMedium}}
            ></DownloadIcon>
        </div>
    );
}