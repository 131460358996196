import './DyadTimeline.scss';
import { useState, useRef, useEffect } from "react";
import styles from '../../styles/global.scss';
// API functions
import { getCountryTimeline } from '../../backend/queryBackend';
// MUI components
import Typography from '@mui/material/Typography';
import RectangleIcon from '@mui/icons-material/Rectangle';
// external libraries
import * as d3 from "d3";

export default function DyadTimeline(props) {
    const timelineRef = useRef();
    const timelineWrapperRef = useRef();
    const [timelineData, setTimelineData] = useState([]);
    const [needFetching, setNeedFetching] = useState(false);
    // control which data to display in timeline
    const [displaySelection, setDisplaySelection] = useState({'agreements': true, 'negotiations': true, 'fatalities': true})

    useEffect(() => {
        // prevents API call from being executed twice
        if (!props.gw_number || needFetching) return;
        setNeedFetching(true);
      }, [props.gw_number]);

    useEffect(( )=> {
        if (!needFetching) return;

        if(props.gw_number){
            getCountryTimeline(`?gw_number=${props.gw_number}`).then(responseData => {
                setTimelineData(responseData)
            })
        } 
    }, [needFetching])

    function handleLegendClick(event){
        // when the user clicks on an element in the legend, then its visibility in the timeline should change
        var newSelection = Object.assign({}, displaySelection)
        newSelection[event] = !newSelection[event]
        setDisplaySelection(newSelection)
    }

    function getDisplayOpacity(type){
        // if a legend item should be displayed, set its opacity to 1 otherwise set it to 0
        if(displaySelection){
            return displaySelection[type]? 1:0
        } else {
            return 1
        }
    }

    function mouseover(event, data){
        // delete any remaining tooltips
        d3.select("#timeline-tooltip").remove();
        // create a new tooltip
        d3.select(timelineWrapperRef.current)
            .append("div")
            .style("position", "absolute")
            .style("opacity", 1)
            .attr("class", "timeline-tooltip")
            .attr("id", "timeline-tooltip")
            .style("background-color", styles.lightSecondary)
            .style("border-radius", styles.borderRadiusSmall)
            .style("padding", "5px")
    }

    function mousemove(event, data){
        // add the html text to the tooltip
        var tooltipText = `<b>${data.year}</b>: ${data.value} ${data.key}`
        d3.select("#timeline-tooltip")
            .style("opacity", 1)
            .html(
                `<div class=line> ${tooltipText} </div>`
            )
            .style('font-size', '0.8rem')
            // need to move tooltip away from mouse to prevent flickering
            .style("left", (event.x) + 10 + "px")
            .style("top", (event.y) + 10 + "px");
    }
            

    function mouseleave(){
        // hide tooltip
        d3.select("#timeline-tooltip")
            .style("opacity", 0)
        // remove the tooltip when hover leaves point
        d3.select("#timeline-tooltip").remove();
    }

    useEffect(() => {
        // only try to display data when there is data to display
        if(timelineData.length > 0){
            // max values for axis length computation
            const maxFatalities = props.countryStats.maxFatalities;
            const maxNegotiationsAgreements = Math.max(props.countryStats.maxAgreements, props.countryStats.maxNegotiations);
            // subgroups for grouped bar display
            const subgroups = ['negotiations', 'agreements', 'fatalities']


            const data = timelineData;
                    
            var svg = d3.select(timelineRef.current);

            // remove all existing elements initially
            svg.selectAll("rect").remove();
            svg.selectAll("g").remove();

            // sizing and position properties
            var width = 500;
            var height = 200;
            const graphPadding = 15;
            const sidePaddingLeft = 25;
            const sidePaddingRight = 35;

            // get the size of the SVG in which the timeline will be drawn
            if(timelineRef.current){
                const svgSize = timelineRef.current.getBoundingClientRect();
                width = svgSize.width;
                height = svgSize.height;
            }

            // create X axis
            const x = d3.scaleBand()
                .domain(timelineData?.map((entry) => entry.year))
                .range([0, width - sidePaddingLeft - sidePaddingRight])
                .padding([0.3])

            // add x Axis to drawing
            svg.append("g")
            .attr("transform", `translate(${sidePaddingLeft},${height - 2*graphPadding})`)
            .attr("class","timelineAxis XAxis")
            .call(d3.axisBottom(x).tickSize(0)); // remove tick lines

            // create Y axis (left)
            const y = d3.scaleLinear()
            .domain([0, maxNegotiationsAgreements])
            .range([ height - 2*graphPadding, graphPadding ]);
            // add left Y axis to drawing
            svg.append("g")
                .attr('transform', `translate(${sidePaddingLeft}, 0)`)
                .attr('opacity', d => getDisplayOpacity('agreements') + getDisplayOpacity('negotiations'))
                .attr("class","timelineAxis")
                .call(d3.axisLeft(y));

            // create second Y axis (right)
            const yRight = d3.scaleLinear()
            .domain([0, maxFatalities])
            .range([ height - 2*graphPadding, graphPadding ]);
            // add right Y axis to drawing
            svg.append("g")
                .attr('transform', 'translate(' + (width - sidePaddingRight) + ', 0)')
                .attr('opacity', d => getDisplayOpacity('fatalities'))
                .attr("class","timelineAxis")
                .call(d3.axisRight(yRight));

            // reduce font size of axis labels
            d3.selectAll(".timelineAxis>.tick>text")
            .style("font-size", '6pt');

            //rotate X axis labels, make labels a bit bigger
            d3.selectAll(".XAxis>.tick>text")
            .style("font-size", '8pt')
            .attr("transform", `translate(${-0.5*graphPadding}, ${graphPadding})rotate(-65)`);

            // Another scale for subgroup position
            const xSubgroup = d3.scaleBand()
                .domain(subgroups)
                .range([0, x.bandwidth()])
                .padding([0.05])

            // color palette = one color per subgroup
            const color = d3.scaleOrdinal()
                .domain(subgroups)
                .range([styles.catLightGrey, styles.catMediumGrey, styles.catDarkGrey])

            function getValue(y, yRight, d){
                // project a data point on left or right axis, depending on its key
                if(d.key === 'fatalities'){
                    let res = yRight(Number(d.value))
                    return res
                } else {
                    let res = y(Number(d.value))
                    return res
                }
            }

            // add background bars for better visibility
            svg.append("g")
                .selectAll("rect")
                .data(data)
                    .join('rect')
                    .attr("x", d => sidePaddingLeft + x(d.year))
                    .attr("y", (maxFatalities > 0)? yRight(maxFatalities):y(maxNegotiationsAgreements))
                    .attr('opacity', 0.5)
                    .attr("width", x.bandwidth())
                    .attr("height", height - 3*graphPadding)
                    .attr("fill", styles.lightSecondary)


            // Show the subgroups bars
            svg.append("g")
                .selectAll("g")
                // Enter in data = loop group per group
                .data(data)
                .join("g")
                    .attr("transform", d => `translate(${x(d.year) + sidePaddingLeft}, 0)`)
                    .selectAll("rect")
                    .data(function(d) { return subgroups.map(function(key) { return {key: key, value: d[key], year:d.year}; }); })
                        .join("rect")
                        .attr("x", d => xSubgroup(d.key))
                        .attr("y", d =>  getValue(y, yRight, d))
                        .attr('opacity', d => getDisplayOpacity(d.key))
                        .attr("width", xSubgroup.bandwidth())
                        .attr("height", d => height - 2*graphPadding - getValue(y, yRight, d))
                        .attr("fill", d => color(d.key))
                        .on("mouseover", (e, d) => mouseover(e, d))
                        .on("mousemove", (e, d) => mousemove(e, d))
                        .on("mouseleave", (e ,d) => mouseleave(e, d));
        }
    }, [timelineData, displaySelection, props.countryStats.maxFatalities])

    return (
        <div className="timeline-view" ref={timelineWrapperRef}>
            {(timelineData.length > 0)?(
            <div className='timeline-legend'>
                <div className='timeline-left-axis'>
                    <div 
                    className={'timeline-legend-item'+ (displaySelection['negotiations']? '' : ' unselected')}
                    key={'legend-negotiations'}
                    onClick={() => handleLegendClick('negotiations')}
                    >
                        <RectangleIcon
                        sx={{ color: styles.catLightGrey, pr: '6px' }}
                        fontSize='8px'
                        ></RectangleIcon>
                        <Typography
                        sx= {{fontSize:'10px'}}
                        >negotiations</Typography>
                    </div>
                    <div 
                    className={'timeline-legend-item'+ (displaySelection['agreements']? '' : ' unselected')}
                    key={'legend-agreements'}
                    onClick={() => handleLegendClick('agreements')}
                    >
                        <RectangleIcon
                        sx={{ color: styles.catMediumGrey, pr: '6px' }}
                        fontSize='8px'
                        ></RectangleIcon>
                        <Typography
                        sx= {{fontSize:'10px'}}
                        >agreements</Typography>
                    </div>
                </div>
                <div 
                className={'timeline-legend-item'+ (displaySelection['fatalities']? '' : ' unselected')}
                key={'legend-fatalities'}
                onClick={() => handleLegendClick('fatalities')}
                >
                    <RectangleIcon
                    sx={{ color: styles.catDarkGrey, pr: '6px' }}
                    fontSize='8px'
                    ></RectangleIcon>
                    <Typography
                    sx= {{fontSize:'10px'}}
                    >fatalities</Typography>
                </div>
            </div>): null}
            {(timelineData.length > 0)?(
                <svg className='timeline-svg' ref={timelineRef}></svg>
            ):
                <div className='loading-text'>
                    <Typography
                    sx= {{
                        fontSize:'12pt',
                        fontStyle:'italic',
                    }}
                    >no data</Typography>
                </div>
            }
        </div>
    );
}