// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".location-graph-legend {\n  position: absolute;\n  bottom: 10px;\n  left: 10px;\n}\n.location-graph-legend .legend-wrapper .legend-item {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n  padding: 2px;\n}\n.location-graph-legend .legend-wrapper .legend-item.unselected {\n  opacity: 0.5;\n}\n.location-graph-legend .legend-wrapper .legend-item .dash-icon {\n  width: 40px;\n  height: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/ConflictExplorer/components/GraphLegend/GraphLegend.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,YAAA;EACA,UAAA;AA2BJ;AAzBQ;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,YAAA;AA2BZ;AA1BY;EACI,YAAA;AA4BhB;AA1BY;EACI,WAAA;EACA,YAAA;AA4BhB","sourcesContent":["@import '../../styles/global_variables';\n\n.location-graph-legend{\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    .legend-wrapper{\n        .legend-item{\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n            justify-content: space-between;\n            cursor: pointer;\n            padding: 2px;\n            &.unselected{\n                opacity: 0.5;\n            }\n            .dash-icon{\n                width: 40px;\n                height: 10px;\n            }\n        } \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#fafafa",
	"primaryLight": "#f0eded",
	"primaryDark": "#c7c7c7",
	"secondaryColor": "#bdbdbd",
	"secondaryLight": "#d7d7d7",
	"secondaryDark": "#8d8d8d",
	"textPrimary": "#4C4C4C",
	"textSecondary": "#000000",
	"neutralColor": "#c7c7c7",
	"neutralLight": "#e0e0e0",
	"neutralDark": "#aeaeae",
	"textNeutral": "#000000",
	"textWhite": "#ffffff",
	"mainBorderRadius": "4px",
	"mapPopupWidth": "500px",
	"infoIconColor": "#abcdef",
	"typeIconColor": "#000000",
	"multipleAppearanceColor": "#ffff00",
	"highlightColor": "#fea500",
	"mapPopUpInteraction": "50px",
	"linkHighlight": "#999999",
	"helpMain": "#b2dfdb",
	"helpLight": "#e5ffff",
	"helpDark": "#82ada9",
	"timelineHighlight": "#000000"
};
export default ___CSS_LOADER_EXPORT___;
