import './Exploration.scss';
import { useRef, useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
// API functions
import { getDataByPeriod, getGraphData, getFilteredByActorNames } from '../../utils/queryBackend';
// MUI components
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// components
import LocationGraphConfiguration from '../../components/LocationGraphConfiguration/LocationGraphConfiguration'
import LocationMapConfiguration from '../../components/LocationMapConfiguration/LocationMapConfiguration'
import PeriodSelectionBar from '../../components/PeriodSelectionBar/PeriodSelectionBar'
import SplitWindow from '../../components/SplitWindow/SplitWindow'
// external libraries
import _ from 'lodash';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';


export default function Exploration(props) {
  const { promiseInProgress } = usePromiseTracker();
  const dummyGraph = {'nodes': [], 'links': [], 'maxNode': 0}
  const exportRef = useRef();
  const [locationData, setLocationData] = useState([]);
  const [graphData, setGraphData] = useState(dummyGraph);
  const [windowState, setWindowState] = useState('mid');
  const [needFetching, setNeedFetching] = useState(false);
  const [haveMapData, setHaveMapData] = useState(false);
  const [neighbourDegree, setNeighbourDegree] = useState(0);
  const [startDate, setStartDate] = useState('2022-06-01');
  const [endDate, setEndDate] = useState('2022-06-30');
  const [nodeMinSize, setNodeMinSize] = useState(1);
  const [selectedInGraph, setSelectedInGraph] = useState([]);
  const [highlightState, setHighlightState] = useState({actorName: '', isHighlighted: false});
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [loadDates, setLoadDates] = useState(false);

  useEffect(() => {
    // prevents API call from being executed twice
    if (!props.gw_number || needFetching) return;
    setNeedFetching(true);
  }, [props.gw_number]);

  function triggerGraphFetching(){
    const hasData = haveMapData;
    setHaveMapData(!hasData);
    setHaveMapData(hasData)
  }

  useEffect(() => {
    if (!needFetching) return;

    if (props.gw_number){
      trackPromise(getGraphData(`?start=${startDate}&end=${endDate}&gw_number=${props.gw_number}`)).then(
        (responseData) => {
          if(responseData===undefined){
            setGraphData(dummyGraph)
            triggerGraphFetching();
          } else {
            setGraphData(responseData);
          }
          if(!haveMapData){
            trackPromise(getDataByPeriod(`?start=${startDate}&end=${endDate}&gw_number=${props.gw_number}`)).then((responseData) => {
              setLocationData(responseData);
              setHaveMapData(true)
            })
          }
          setLoadDates(true);
        }
      )
    }
  }, [needFetching])

  function expandMap(){
    if(windowState === 'right'){
      setWindowState('mid');
    } else {
      setWindowState('left');
    }  
  };

  function expandGraph(){
    if(windowState === 'left'){
      setWindowState('mid');
    } else {
      setWindowState('right');
    }
  };

  function showMapButton(){
    if(windowState === 'left'){
      return false;
    } else {
      return true;
    }
  }

  function showGraphButton(){
    if(windowState === 'right'){
      return false;
    } else {
      return true;
    }
  }

  function showGraphConfig(){
    if(windowState === 'left'){
      return false;
    } else {
      return true;
    }
  }

  function showMapConfig(){
    if(windowState === 'right'){
      return false;
    } else {
      return true;
    }
  }

  function filterEvents(selected_actors){
    if(haveMapData){
      const filteredActors = graphData.nodes.filter(node => (node['appearance'] >= nodeMinSize)).map((actor) => {return {name: actor['actor_name'], id:actor['actor_id']}});
      var commonValues = filteredActors.map(actor => actor['id']);
      if(selected_actors.length > 0){
        commonValues = _.intersection(commonValues, selected_actors);
      } 
      const relevantNames = filteredActors.filter(actor => commonValues.includes(actor.id)).map(actor => actor.name)
      if(props.gw_number){
        getFilteredByActorNames(`?names=${relevantNames}&start=${startDate}&end=${endDate}&gw_number=${props.gw_number}`).then((responseData)=>{
          const filteredEvents = responseData;
          setLocationData(filteredEvents);
        })
      }
    }
  }

  function getByDate(start, end){
    setStartDate(start.format('YYYY-MM-DD'));
    setEndDate(end.format('YYYY-MM-DD'));
    if(props.gw_number){
      trackPromise(getDataByPeriod(`?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}&gw_number=${props.gw_number}`)).then(
        (responseData) => {
          setLocationData(responseData)
        }
      )
      trackPromise(getGraphData(`?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}&gw_number=${props.gw_number}`)).then(
        (responseData) => setGraphData(responseData)
      );
    }
  }

  function setMinSize(minSize){
    setNodeMinSize(minSize);
    const filteredActorNames = graphData.nodes.filter(node => (node['appearance'] >= minSize)).map((actor) => {return actor['actor_name']});
    if(props.gw_number){
      getFilteredByActorNames(`?names=${filteredActorNames}&start=${startDate}&end=${endDate}&gw_number=${props.gw_number}`).then((responseData)=>{
        const filteredEvents = responseData;
        setLocationData(filteredEvents);
      });
    }
  }

  function updateNeighbourDegree(newDegree){
    setNeighbourDegree(newDegree);
  }

  function findActor(findState){
    setHighlightState(findState)
  }

  function updateGraphSelection(newValue){
    setSelectedInGraph(newValue)
  }

  // intro which is displayed when clicking on the help button
  var introState = {
    initialStep: 0,
    steps: [
      {
        title: "Welcome to the Conflict Networks Explorer",
        element: ".location-view",
        intro: `This tool will help you explore conflict-related information in ${props.countryName}.`

      }, 
      {
        title: "Relevant Period",
        element: ".period-selection-items",
        intro: "Set the period for which you want to display data by setting the start and end dates. You can also select a different time unit, to set the period by month or year."
      },
      {
        title: "Visualisation",
        element: ".export-location-wrapper",
        intro: "The data for your selected range will be displayed here. On the left, actors are represented as nodes in the graph. By clicking on the nodes, the map on the right is filtered for the selected actors."
      },
      {
        title: "Node Interaction",
        element: ".location-graph-wrapper",
        intro: "You can click and drag nodes around to create layouts better suiting your needs."
      },
      {
        title: "Network Graph",
        element: ".location-graph-legend",
        intro: "Links between nodes represent interactions between actors. Depending on the type of interaction, it is represented by a cooperative or opposive link. The width of the links represents the number of mutual involvements. Links can be hidden from the graph through the legend. Get more information about the interactions of two actors by clicking on a link."
      },
      {
        title: "Selection Degree",
        element: ".degree",
        intro: "Set whether to select first degree neighbours too when selecting a node in the graph, or not."
      },
      {
        title: "Minimum Size",
        element: ".threshold",
        intro: "Set the minimum number of events in which an actor needs to be involved for its node to appear in the graph."
      },
      {
        title: "Reset Selection",
        element: ".graph-reset-select",
        intro: "Reset the node selection to display all events on the map."
      },
      {
        title: "Find Nodes",
        element: ".find-actors",
        intro: "You can highlight an actor's node in the graph."
      },
      {
        title: "Icon Explanation",
        element: ".actor-interaction-legend",
        intro: "Each actor type is represented by a specific icon. Here you can learn what the different icons mean."
      },
      {
        title: "Map View",
        element: ".location-map-wrapper",
        intro: "On the map, each event is represented by a combination of the actor icons of the two main actors involved. Events are coloured by their event type and can be hidden and displayed through the legend. By clicking on an event, more detailed information will be provided."
      },
      {
        title: "View Controls",
        element: ".window-control-location",
        intro: "Expand each of the views to full screen width if necessary."
      },
      {
        title: "Now it's your turn!",
        element: ".export-location-wrapper",
        intro: "Explore the events and relations to learn more."
      }
    ]
  };

  const onExit = () => {
    setStepsEnabled(false);
  };

  return (
    <div className="location-view">
      <div>
          <Steps
          enabled={stepsEnabled}
          steps={introState.steps}
          initialStep={introState.initialStep}
          onExit={onExit}
          />
      </div>
      <PeriodSelectionBar 
      gw_number={props.gw_number}
      getByDate={getByDate} 
      initialStart={startDate} 
      initialEnd={endDate}
      eventTypeColours={props.eventTypeColours}
      applyChangeEventTypeColours={props.applyChangeEventTypeColours}
      linkTypeColours={props.linkTypeColours}
      applyChangeGraphSettings={props.applyChangeGraphSettings}
      setStepsEnabled={setStepsEnabled}
      loadDates={loadDates}
      setLoadDates={setLoadDates}
      ></PeriodSelectionBar>
      <div className='location-selections-wrapper'>
        {showGraphConfig()? 
        <LocationGraphConfiguration
        setMinSize={setMinSize}
        maxNode={graphData.maxNode}
        setNeighbourDegree={updateNeighbourDegree}
        isSelectable={true}
        graphData={graphData}
        findActor={findActor}
        filterEvents={filterEvents}
        updateSelectedInGraph={updateGraphSelection}
        ></LocationGraphConfiguration> : null}
        {showMapConfig()? 
        <LocationMapConfiguration 
        numEvents={locationData.length}
        ></LocationMapConfiguration> : null}
      </div>
      <div className='export-location-wrapper' ref={exportRef}>
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={promiseInProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <SplitWindow 
        gw_number={props.gw_number}
        start={startDate}
        end={endDate}
        graphData={graphData}
        neighbourDegree={neighbourDegree}
        filterEvents={filterEvents}
        location='left' 
        selectedInGraph={selectedInGraph}
        minSize={nodeMinSize}
        windowState={windowState}
        actors={props.actors} 
        actorNames={props.actorNames} 
        eventTypeColours={props.eventTypeColours}
        linkTypeColours={props.linkTypeColours}
        highlightState={highlightState}
        updateGraphSelection={updateGraphSelection}
        labelSize={props.labelSize}
        linkWidth={props.linkWidth}
        ></SplitWindow>
        <div className='button-location-wrapper'>
          {showMapButton()?
            <Button
            className='window-control-location'
            variant="contained"
            onClick={expandMap}
            >
                <ChevronLeftIcon
                fontSize="small"
                ></ChevronLeftIcon>
            </Button>
            :null
          }
          {showGraphButton()?
            <Button
            className='window-control-location'
            variant="contained"
            onClick={expandGraph}
            >
                <ChevronRightIcon
                fontSize="small"
                ></ChevronRightIcon>
            </Button>
            :null
          }
        </div>
        <SplitWindow 
        gw_number={props.gw_number}
        start={startDate}
        end={endDate}
        location='right' 
        windowState={windowState} 
        events={locationData} 
        actors={props.actors}
        actorNames={props.actorNames}
        eventTypeColours={props.eventTypeColours}
        linkTypeColours={props.linkTypeColours}
        ></SplitWindow>
      </div>
    </div>
  );
}