const DEV_URL = 'http://127.0.0.1:8000'; // point to locally hosted application
const PROD_URL = 'https://peace-observatory-backend-9yccq.ondigitalocean.app'; // point to server hosted application

export const BASE_URL = PROD_URL; 
// TODO: Change to development URL if you want to serve the backend locally

export var hasFetchErrorConflictExplorer = false;

function handleFetchError(error){
    console.log('error', error)
    hasFetchErrorConflictExplorer = true
}

export const getDataByPeriod = async (params) => {
    /**
     * Reqest data set with events lying inside the specified period.
     * - params = [start, end, gw_number]
     */
    const requestURL = `${BASE_URL}/get-data-by-period${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getAvailableDates = async (params) => {
    /**
     * Reqest array of all available dates between start and end date, 
     * given a specific time unit (year, month, day).
     * - params = [unit, gw_number]
     */
     const requestURL = `${BASE_URL}/get-available-dates${params}`;
     const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getGraphData = async (params) => {
    /**
     * Reqest the graph data for the specified time period.
     * -params = [start, end, gw_number]
     * -returns: {'nodes': list, 'links': list}
     */
     const requestURL = `${BASE_URL}/get-event-graph${params}`;
     const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}


export const getFilteredByActorNames = async (params) => {
    /**
     * Reqest the data set filtered based on one or multiple actor name(s).
     * -params = [names, start, end, gw_number]
     * -returns: {}
     */
    const requestURL = `${BASE_URL}/get-filtered-by-actor-names${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getReportsByFilters = async (params) => {
    /**
     * Reqest the data set filtered based on ids, period, event types and/or actor names.
     * -params = [ids, start, end, eventTypes, actorNames, gw_number]
     * -returns: {}
     */
    const requestURL = `${BASE_URL}/get-reports-by-filters${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}

export const getLinkIds = async (params) => {
    /**
     * Reqest the ids of all events where two parties were involved.
     * -params = [actorNames, gw_number]
     * -returns: {}
     */
    const requestURL = `${BASE_URL}/get-link-ids${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}


export const getFullActors = async (params) => {
    /**
     * Request all actors for the selected country sorted by descending number of events.
     * -params = [gw_number]
     * -returns: [{
     * id: int, 
     * dataBasedName: str,
     * actorType: int,
     * collaborations: {str: number},
     * oppositions: {str: number},
     * eventTypes: list(int),
     * eventTypeSummary: {int: float},
     * eventReportIds: list(int),
     * }]
     */
    const requestURL = `${BASE_URL}/get-full-actors${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });

    return data;
}


export const getEventTypeColours = async (params) => {
    /**
     * Reqest the event type colour assignment.
     * -params = None
     * -returns: dict
     */
    const requestURL = `${BASE_URL}/get-event-type-colours${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const updateEventTypeColours = async (params) => {
    /**
     * Update event type colour assignment.
     * -params = newColours: dict[str, str]
     * -returns: (dict(str, str)) 
     */
    const requestURL = `${BASE_URL}/set-event-type-colours?`;
    const data = await fetch(requestURL,
        {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params)
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const getLinkTypeColours = async (params) => {
    /**
     * Request link type colour assignment.
     * -params = None
     * -returns: (dict(str, str)) 
     */
    const requestURL = `${BASE_URL}/get-link-type-colours${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const updateLinkTypeColours = async (params) => {
    /**
     * Update link type colour assignment.
     * -params = newColours: dict[str, str]
     * -returns: (dict(str, str)) 
     */
    const requestURL = `${BASE_URL}/set-link-type-colours?`;
    const data = await fetch(requestURL,
        {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params)
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const getPeriodInfo = async (params) => {
    /**
     * Request an actors activity grouped by months.
     * -params = [gw_number]
     * -returns: Object
     */
    const requestURL = `${BASE_URL}/get-period-info${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const getActorTimeline = async (params) => {
    /**
     * Request an actors activity grouped by months.
     * -params = [gw_number]
     * -returns: Object
     */
    const requestURL = `${BASE_URL}/get-actor-timeline${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const getCentroidByGW = async (params) => {
    /**
     * Request a countries centroid coordinates.
     * -params = [gw_number]
     * -returns: Object
     */
    const requestURL = `${BASE_URL}/get-centroid-location${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}

export const getMapOverlay = async (params) => {
    /**
     * Request the world shape minus the selected country.
     * -params = [gw_number]
     * -returns: geojson
     */
    const requestURL = `${BASE_URL}/get-map-overlay${params}`;
    const data = await fetch(requestURL,
        {
            method: 'GET'
        }
    ).then(response => response.json(),
    (error) => {
        if (error) {
          // handle error here
          handleFetchError(error)
        }
      });
    return data;
}