import './PodcastView.scss';
import styles from '../../styles/global.scss';
// predefined data
import content from '../../content/content';
// MUI components
import Typography from '@mui/material/Typography';

export default function PodcastView(props) {
  return (
    <div className="podcast-view">
        <Typography 
        fontSize={styles.fontHuge}
        color={styles.textLight}>
            {content.PLACEHOLDER}
        </Typography>
    </div>
  );
}