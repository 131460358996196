import './DownloadItem.scss';
import styles from '../../styles/global.scss';
// API functions
import { downloadFile } from '../../backend/queryBackend';
// MUI components
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';

export default function DownloadItem(props) {

    function handleDownload(folder, filename, filetype){
        // forward the request for downloading a file to the API
        const name = filename + '.' + filetype
        downloadFile(`?folder=${folder}&filename=${name}`)
    }

    return (
        <div className="download-item" 
        style={{cursor:'pointer'}}
        onClick={() => handleDownload(props.folder, props.item.filename, props.item.filetype)}>
            <Typography
            color={styles.textMedium}
            fontSize={styles.fontSmall}
            >{props.item.filename}.{props.item.filetype}</Typography>
            <DownloadIcon
            color="secondary"
            sx={{ fontSize: styles.fontLarge }}
            ></DownloadIcon>
        </div>
    );
}