import './DyadCategory.scss';
import { useState } from "react";
import styles from '../../styles/global.scss';
// MUI components
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function DyadCategory(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
        sx={{ width: '100%' }}
        component="nav"
        className='dyad-cat-list'
        disablePadding
        >
            <ListItemButton 
            disabled={props.items?.length === 0}
            onClick={handleClick}
            className='list-button'
            sx={{
                '& .MuiSvgIcon-root': {
                    color: styles.textMedium,
                },
            }}
            >
                <div className='list-text-wrapper'>
                    <Typography
                    fontSize={styles.fontSmall}
                    color={styles.textMedium}
                    fontWeight='bold'
                    mr={4}
                    >{props.items?.length}</Typography>
                    <ListItemText 
                    primary={
                    <Typography
                        fontSize={styles.fontSmall}
                        color={styles.textMedium}
                        >{props.category}</Typography>}
                    />
                </div>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className='dyad-sublist'>
                    {props.items.map((item, idx) => (
                        <ListItemButton 
                        onClick={() => props.selectDyad(item)}
                        key={`dyad-list-${item.dyaiddID}-${idx}`}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </List>
    );
}