import './PeaceView.scss';
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
// API functions
import { getNegotiationsAgreements, getDyads, getDyadOptions } from '../../backend/queryBackend';
// predefined data
import world_data from '../../assets/worldGW.json';
// MUI components
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// components
import DisplayHeader from '../DisplayHeader/DisplayHeader';
import CountryView from '../CountryView/CountryView';
import DyadView from '../DyadView/DyadView';
import WorldMap from '../WorldMap/WorldMap';

export default function PeaceView(props) {
  const { promiseInProgress } = usePromiseTracker();
  const [needNegAgreeFetching, setNeedNegAgreeFetching] = useState(false);
  // keeping track of page routing
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedDyad, setSelectedDyad] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  // data to display
  const [dyads, setDyads] = useState([])
  const [negotiationsAgreements, setNegotiationsAgreements] = useState();
  // control options for switch on map display
  const options = ['negotiations', 'agreements']
  const [selectedOption, setSelectedOption] = useState(options[0])
  // key names for country and dyad identifiction
  const identification = 'name';
  const dyadIdentification = 'id';
  //search data
  const [searchData, setSearchData] = useState([]);

  function handleSelect(selection){
    if(selection){
      if(selection['type'] === 'country'){
        // handle the selection of a country
        if(selection['id'] != 0 && (negotiationsAgreements[selection['id']])){
          setSelectedCountry(selection);
          setSearchParams({'country': selection[identification]});
        }
      } else {
          handleDyadSelect(selection);
      }
    }
  }

  useEffect(()=> {
    // map the world data to {id, name, type} objects to have consistent data
    let mappedData = world_data['features'].map(feature => {
      return {
        'id': Number(feature['gw_number']), 
        [identification]: feature['properties']['name'],
        'type': 'country'
      }
    })
    // also get data on dyads
    trackPromise(getDyadOptions(`?`)).then(responseData => {
      mappedData = mappedData.concat(responseData)
      setSearchData(mappedData)
    })
  }, [needNegAgreeFetching])

  function handleDyadSelect(selection){
    // handle the selection of a dyad
    const paramName = 'dyad';
    searchParams.delete(paramName); // delete previously set parameters to prevent doubled params
    setSelectedDyad(selection);

    if(selection){
      searchParams.append(paramName, selection[dyadIdentification]);
      setSearchParams(searchParams);
    }
  }

  useEffect(() => {
    // whenever the selected country changes (and is not null), fetch its related dyads from the API
    if(selectedCountry && selectedCountry['id']){
      trackPromise(getDyads(`?gw_number=${selectedCountry['id']}`)).then(responseData => {
        setDyads(responseData)
      })
    }
  }, [selectedCountry])

  useEffect(() => {
    // prevents API call from being executed twice
    if (needNegAgreeFetching) return;
    setNeedNegAgreeFetching(true);
  });

  useEffect(() => {
    if(!needNegAgreeFetching){
      return
    } else {
      trackPromise(getNegotiationsAgreements(`?`)).then((responseData) => {
        setNegotiationsAgreements(responseData)
      })
    }
  }, [needNegAgreeFetching])

  useEffect(() => {
    // whenever the search parameters change (e.g. upon reload or manual adjustment), fetch corresponding data
    let pathCountry = searchParams.get("country");
    if(pathCountry){
      // check whether a country is selected in the parameters
      const matchedGW = world_data['features'].find((country) => country['properties']['name'] === pathCountry)['gw_number'];
      const matchedCountry = {'name': pathCountry, 'id': matchedGW, 'type': 'country'}      
      if(matchedCountry){
        setSelectedCountry(matchedCountry)
      }
    } else {
      setSelectedCountry(undefined);
    }

    let pathDyad = Number(searchParams.get("dyad"));
    if(pathDyad){
      // check whether a dyad is selected in the parameters
      const flatDyads = dyads.map(category => category.dyadItems).flat()
      const matchedDyad = flatDyads.find((dyad) => dyad[dyadIdentification] === pathDyad);
      if(matchedDyad){
        setSelectedDyad(matchedDyad)
      } 
    } else {
      setSelectedDyad(undefined);
    }

  }, [searchParams, dyads.length])

  function handleCountryClick(feature){
    // handle the click or selection of a country
    handleSelect({id: feature['gw_number'], name: feature['properties']['name'], type:'country'})
  }

  function handleSwitchChange(selection){
    setSelectedOption(selection)
  }

  return (
    <div className="peace-view">
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={promiseInProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {selectedCountry? 
        (selectedDyad?
          <DyadView
          selectedDyad={selectedDyad}
          setSelectedDyad={handleDyadSelect}
          searchParamName='dyad'
          hasCountry
          ></DyadView>
          :
          <CountryView
          selectedCountry={selectedCountry}
          searchParamName='country'
          setSelectedCountry={setSelectedCountry}
          selectDyad={handleDyadSelect}
          dyads={dyads}
          ></CountryView> 
        )
      : 
      (selectedDyad?
        <DyadView
        selectedDyad={selectedDyad}
        setSelectedDyad={handleDyadSelect}
        searchParamName='dyad'
        ></DyadView>
        :
      (<div className='world-view'>
        <DisplayHeader
        handleSwitchChange={handleSwitchChange}
        selectedOption={selectedOption}
        displayOptions={options}
        searchOptions={searchData}
        handleSelect={handleSelect}
        identification={identification}
        optionsKeys={['type', identification, 'id']}
        />
        {negotiationsAgreements? (
        <WorldMap
        world_data={world_data}
        options={['negotiations', 'agreements']}
        handleCountryClick={handleCountryClick}
        colourData={negotiationsAgreements}
        selectedOption={selectedOption}
        ></WorldMap>
        ):null}
        
      </div>
      )
      )}
    </div>
  );
}