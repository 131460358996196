// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-display {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  min-height: 0;\n}\n.app-display .display-wrapper {\n  margin: 0 18px 18px 18px;\n  width: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/components/AppDisplay/AppDisplay.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AAgCJ;AA/BI;EACI,wBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;AAiCR","sourcesContent":["@import  '../../styles/global';\n\n.app-display{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    min-height: 0;\n    .display-wrapper{\n        margin: 0 $padding-big $padding-big $padding-big;\n        width: 100%;\n        max-width: 100%;\n        overflow: hidden;\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkPrimary": "#17344E",
	"mediumPrimary": "#22486A",
	"lightPrimary": "#3D76AB",
	"textLight": "#ffffff",
	"textMedium": "#17344E",
	"textDark": "#000000",
	"darkSecondary": "#E0E0E0",
	"lightSecondary": "#F5F5F5",
	"catLight": "#fbe5b9",
	"catMedium": "#f1c232",
	"catDark": "#c38a19",
	"catGreen": "#228833",
	"catPurple": "#AA3377",
	"catBlue": "#4477AA",
	"fontHuge": "72pt",
	"fontSemiHuge": "64pt",
	"fontXLarge": "48pt",
	"fontLarge": "32pt",
	"fontMedium": "28pt",
	"fontSmall": "18pt",
	"fontXSmall": "14pt",
	"fontText": "12pt",
	"fontXXSmall": "10pt",
	"borderRadiusSmall": "4px",
	"catLightGrey": "#bdbdbd",
	"catMediumGrey": "#636363",
	"catDarkGrey": "#272727",
	"noDataColour": "#ffffff",
	"inputFilterTextWidth": "350px",
	"maxWidthText": "22cm"
};
export default ___CSS_LOADER_EXPORT___;
