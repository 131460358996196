import './DisplayHeader.scss';
// components
import DisplaySwitch from '../DisplaySwitch/DisplaySwitch';
import SearchBox from '../SearchBox/SearchBox';

export default function DisplayHeader(props) {

  return (
    <div className='display-controls'>
        <DisplaySwitch 
        handleSwitchChange={props.handleSwitchChange}
        selectedOption={props.selectedOption}
        options={props.displayOptions}></DisplaySwitch>
        <SearchBox 
        handleSelect={props.handleSelect}
        options={props.searchOptions}
        optionsKeys={props.optionsKeys}
        identification={props.identification}
        ></SearchBox>
    </div>
  );
}