import './AppHeader.scss';
// components
import AppTitle from'./AppTitle/AppTitle';
import Navigation from './Navigation/Navigation';


export default function AppHeader(props) {
  return (
    <div className="app-header">
        <div className='header-wrapper'>
            <AppTitle></AppTitle>
            <Navigation 
            ></Navigation>
        </div>
    </div>
  );
}