import './ConflictView.scss';
import { useState, useEffect } from "react";
import styles from '../../styles/global.scss';
import { useSearchParams } from "react-router-dom";
// API functions
import {getEventsFatalities, getCurrentEventDataYear} from '../../backend/queryBackend';
// predefined data
import content from '../../content/content';
import world_data from '../../assets/worldGW.json';
// MUI components
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// components
import DisplayHeader from '../DisplayHeader/DisplayHeader';
import BackButton from '../BackButton/BackButton';
import WorldMap from '../WorldMap/WorldMap';
import ConflictExplorer from '../ConflictExplorer/ConflictExplorer';

export default function ConflictView(props) {
  // year for which data is displayed on map
  const [eventsYear, setEventsYear] = useState();

  // data to display on world map (events/fatalities per country)
  const [eventFatalities, setEventFatalities] = useState();

  // to prevent API call from being executed twice
  const [needFetching, setNeedFetching] = useState(false); 

  // keep track of country selection in variable and search parameters
  const [selectedCountry, setSelectedCountry] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  // controlling state of switch
  const options = ['fatalities', 'events']
  const [selectedOption, setSelectedOption] = useState(options[0])

  // key name to use for identifying countries
  const identification = 'name';

  useEffect(() => {
    // prevents API call from being executed twice
    if (needFetching) return;
    setNeedFetching(true);
  });

  useEffect(() => {
    if(!needFetching) return;
    else{
      getEventsFatalities(`?`).then((responseData) => {
        setEventFatalities(responseData)
      })
      getCurrentEventDataYear('?').then((responseData) => {
        setEventsYear(responseData);
      })
    }
  }, [needFetching])

  function getSearchData(){
    // map the world data to {id, name} objects to have consistent data
    const mappedData = world_data['features'].map(feature => {
      return {
        'id': feature['gw_number'], 
        [identification]: feature['properties']['name'],
        'type': 'country'
      }
    })
    return mappedData
  }
  

  function handleSelect(selection){
    if(selection){
      if(selection['type'] === 'country'){
        // select the country that was clicked, and update the search parameters in URL 
        if(selection['id'] != 0 && (eventFatalities[selection['id']])){
          setSelectedCountry(selection)
          setSearchParams({'country': selection[identification]});
        }
      }
    } 
  }

  function resetSearch(){
    // unset any selected country to route back to the conflict map view
    setSelectedCountry(null)
  }

  useEffect(() => {
    // upon a change in the search parameters, get the value of the 'country' parameter and set the selected country accordingly.
    let pathCountry = searchParams.get("country");
    if(pathCountry){
      const matchedGW = world_data['features'].find((country) => country['properties']['name'] === pathCountry)['gw_number'];
      const matchedCountry = {'name': pathCountry, 'id': matchedGW, 'type': 'country'}
      if(matchedCountry){
        setSelectedCountry(matchedCountry)
      }
    }

  }, [searchParams])

  function handleSwitchChange(selection){
    // change map display between events and fatalities
    setSelectedOption(selection)
  }


  function handleCountryClick(feature){
    // update the selected country 
    handleSelect({id: feature['gw_number'], name: feature['properties']['name'], 'type': 'country'})
  }

  return (
    <div className="conflict-view">
      {selectedCountry?
      <div className='conflict-explorer'>
        <div className='conflict-explorer-header'>
          <BackButton 
          className='button-wrapper' 
          searchParamName={'country'}
          resetSearch={resetSearch}
          buttonText={'world view'}></BackButton>
          <Typography
          color={styles.textLight}
          fontSize={styles.fontMedium}
          >{selectedCountry[identification]}</Typography>
          <div className="right"></div>
        </div>
        <ConflictExplorer
        gw_number={selectedCountry['id']}
        countryName={selectedCountry[identification]}
        ></ConflictExplorer>
        <div className='data-source-info'>
          <Typography 
          color={styles.textLight}
          fontSize={'9pt'}
          >
            {content.EVENT_ACCESS_TEXT} 
            <Link 
            color={styles.textLight} 
            href={content.EVENT_ACCESS_LINK}
            >
              {content.EVENT_ACCESS_LINK}
            </Link>
          </Typography>
        </div>
      </div>
      :
      <div className='world-view'>
        <DisplayHeader
        handleSwitchChange={handleSwitchChange}
        displayOptions={options}
        selectedOption={selectedOption}
        searchOptions={getSearchData()}
        handleSelect={handleSelect}
        identification={identification}
        optionsKeys={['type', identification]}
        />
        {eventFatalities?(
          <WorldMap
          world_data={world_data}
          options={['fatalities', 'events']}
          handleCountryClick={handleCountryClick}
          colourData={eventFatalities}
          selectedOption={selectedOption}
          ></WorldMap>
        ):null}
        <div className='data-source-info'>
          <Typography
          color={styles.textLight}
          fontSize={'9pt'}
          pr={1}
          >Data for year {eventsYear}.</Typography>
          <Typography 
          color={styles.textLight}
          fontSize={'9pt'}
          >
            {content.EVENT_ACCESS_TEXT} 
            <Link 
            color={styles.textLight} 
            href={content.EVENT_ACCESS_LINK}
            >
              {content.EVENT_ACCESS_LINK}
            </Link>
            .
          </Typography>
        </div>
      </div>
      }
    </div>
  );
}