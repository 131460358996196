import './Navigation.scss';
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// predefined data
import content from '../../../content/content';
// MUI components
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function catchInitial(pathname){
    if(pathname === '/'){
        return '/peace-data' 
        // to catch that '/' and '/peace-data' point to the same view and should be treated identically
    } else {
        return pathname
    }
}

export default function Navigation(props) {
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState(catchInitial(location.pathname));

    const handleTabChange = (event, newSelection) => {
        if (newSelection !== null) {
            setSelectedTab(newSelection);
        }
    };

    useEffect(() => {
        setSelectedTab(catchInitial(location.pathname))
    }, [location.pathname])

    const tabs = [
        {
            text: content.MAIN_TAB,
            value: '/peace-data'
        },
        {
            text: content.EXPLORER_TAB,
            value: '/conflict-networks'
        },
        {
            text: content.VISUALISATIONS_TAB,
            value: '/visualisations'
        },
        {
            text: content.PODCASTS_TAB,
            value: '/podcasts'
        },
        {
            text: content.DOWNLOAD_TAB,
            value: '/download'
        },
        {
            text: content.ABOUT_TAB,
            value: '/about'
        }];


    return (
        <div className="navigation-wrapper" id='navigation-wrapper'>
            <ToggleButtonGroup
            value={selectedTab}
            exclusive
            onChange={handleTabChange}
            aria-label="tab selection"
            size='small'
            sx={{
                flexWrap: 'wrap',
            }}
            >
                {tabs.map((tab, index) => (
                    <ToggleButton
                    component={Link} to={tab.value}
                    key={'tab-' + index}
                    value={tab.value}
                    variant="contained"
                    sx={{textAlign: 'center'}}
                    >{tab.text}</ToggleButton>
                ))}
            </ToggleButtonGroup>
            
        </div>
    );
}