import './LocationGraphConfiguration.scss';
import { useState } from 'react';
// MUI components
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

export default function LocationGraphConfiguration(props) {
    const [actorSelection, setActorSelection] = useState('');
    const [selectNeighbours, setSelectNeighbours] = useState(false);
    const [nodeThreshold, setNodeThreshold] = useState(1);

    const selectHeight = 25;
    const selectWidth = 50;

    function handleDegreeChange(event){
        const newDegree = selectNeighbours? 0 : 1;
        setSelectNeighbours(!selectNeighbours);
        props.setNeighbourDegree(newDegree);
    }

    function handleThresholdChange(event){
        const newThreshold = event.target.value;
        setNodeThreshold(newThreshold);
        props.setMinSize(newThreshold);
    }

    function unselectAll(){
        props.filterEvents([]);
        props.updateSelectedInGraph([]);
    }

    function handleActorSelection(event){
        const newActor = event.target.value;
        setActorSelection(newActor);
    }

    function highlightActor(){
        props.findActor({'actorName': actorSelection, 'isHighlighted': true})
    }

    function resetHighlight(){
        setActorSelection('')
        props.findActor({'actorName': '', 'isHighlighted': false})
    }

    function getSearchNames(){
        var names = props.graphData.nodes.map(node => node.actor_name)
        var sortedNames = names.sort()
        return sortedNames
    }


    return(
        <div className='location-graph-config'>
            {props.isSelectable? (
            <div className='location-graph-group'>
                <FormControl 
                sx={{ p: 1, minWidth: 120 }} 
                size="small"
                color='secondary'
                className='loc-graph-selector degree'
                >
                    <Typography 
                    variant="button" 
                    display="block"
                    mx={1} 
                    sx={{fontSize:'0.7rem', color: 'primary.contrastText'}}
                    >
                        filter:
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography fontSize='0.7rem'>node only</Typography>
                        <Switch  
                        color='secondary'
                        size='small'
                        checked={selectNeighbours}
                        onChange={handleDegreeChange}
                            />
                        <Typography fontSize='0.7rem'>node and neighbours</Typography>
                    </Stack>
                </FormControl>
            </div>) :null}
            <div className='location-graph-group'>
                <FormControl 
                sx={{ p: 1, minWidth: 120 }} 
                size="small"
                color='secondary'
                className='loc-graph-selector threshold'
                >
                    <Typography 
                    variant="button" 
                    display="block"
                    mx={1} 
                    sx={{fontSize:'0.7rem', color: 'primary.contrastText'}}
                    >
                        min. event count:
                    </Typography>
                    <Select
                    labelId="node-threshold-label"
                    id="node-threshold"
                    value={nodeThreshold}
                    onChange={handleThresholdChange}
                    renderValue={(value) => (<Typography fontSize='0.8rem' sx={{color: 'primary.contrastText'}} key='selected-minsize-text'>{value}</Typography>)}
                    style={{ height: selectHeight, minWidth: selectWidth }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: '50%' } } }}
                    >
                        {props.maxNode? 
                        [...Array(props.maxNode).keys()].map((i) => {
                            return <MenuItem key={`threshold-${i+1}`} value={i+1}>{i+1}</MenuItem>
                        }    
                        ):null}
                    </Select>
                </FormControl>
            </div>
            {props.isSelectable? (
            <div className='location-graph-group'>
                <Button
                id="unselect-all"
                variant="contained"
                color="primary"
                disableElevation
                onClick={unselectAll}
                className='graph-reset-select'
                sx={{p:1, fontSize:'0.7rem'}}
                size='small'
                >
                    reset selection
                </Button>
            </div>
            ):null}
            <div className='location-graph-group find-actors'>
                <FormControl 
                sx={{ p: 1 }} 
                size="small"
                color='secondary'
                className='actor-graph-selector'
                >
                    <Select
                    labelId="actor-graph-selector-label"
                    displayEmpty
                    id="actor-graph-selector"
                    value={actorSelection}
                    onChange={handleActorSelection}
                    style={{ height: selectHeight }}
                    MenuProps = {{ sx: {height: '50%'}}}
                    className='actor-highlight-select'
                    renderValue={(actorSelection) => {
                        if (actorSelection.length === 0) {
                          return (<Typography 
                            sx={{fontSize:'1rem', color: 'primary.contrastText'}}
                            >
                                find actor
                            </Typography>);
                        }
            
                        return actorSelection;
                      }}
                    >
                        <MenuItem disabled value="">
                            <Typography>find actor</Typography>
                        </MenuItem>
                        {props.graphData.nodes? getSearchNames().map(name => {
                            return <MenuItem className='selected-actor-name' key={`time-actor-${name}`} value={name}>{name}</MenuItem>
                        }):[]}
                    </Select>
                    <Button
                    id="highlight"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={highlightActor}
                    className='graph-select'
                    sx={{fontSize:'0.6rem'}}
                    size='small'
                    disabled={actorSelection===''}
                    >
                        highlight
                    </Button>
                    <Button
                    id="highlight-reset"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={resetHighlight}
                    className='graph-select'
                    sx={{fontSize:'0.6rem'}}
                    size='small'
                    disabled={actorSelection===''}
                    >
                        reset
                    </Button>
                </FormControl>
            </div>
            <div className='location-graph-group'>
                <Typography 
                fontSize='0.8rem'
                sx={{ p: 1, color: 'primary.contrastText' }}
                className='graph-num-events'
                >{props.graphData.nodes.filter(node => (node['appearance'] >= nodeThreshold)).length} nodes
                </Typography>
            </div>
        </div>
    );
}