// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actor-card-spider {\n  margin: 0 10px;\n  height: 100%;\n  width: 100%;\n}\n.actor-card-spider .actor-card-svg {\n  width: 100%;\n  height: 100%;\n}\n.actor-card-spider .spider-legend {\n  position: relative;\n  top: -50px;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n.actor-card-spider .spider-legend .spider-legend-item {\n  display: flex;\n  flex-direction: row;\n  padding: 0 3px;\n  cursor: pointer;\n}\n.actor-card-spider .spider-legend .spider-legend-item.unselected {\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/components/ConflictExplorer/components/ActorCard/ActorSpider/ActorSpider.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,YAAA;EACA,WAAA;AA2BJ;AAzBI;EACI,WAAA;EACA,YAAA;AA2BR;AAzBI;EACI,kBAAA;EACA,UAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;AA2BR;AAzBQ;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AA2BZ;AAzBY;EACI,YAAA;AA2BhB","sourcesContent":["@import '../../../styles/global_variables';\n\n.actor-card-spider{\n    margin: 0 10px;\n    height:100%;\n    width: 100%;\n\n    .actor-card-svg{\n        width: 100%;\n        height: 100%;\n    }\n    .spider-legend{\n        position: relative;\n        top: -50px;\n        left: 0;\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n\n        .spider-legend-item{\n            display: flex;\n            flex-direction: row;\n            padding: 0 3px;\n            cursor: pointer;\n            \n            &.unselected {\n                opacity: 0.5;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#fafafa",
	"primaryLight": "#f0eded",
	"primaryDark": "#c7c7c7",
	"secondaryColor": "#bdbdbd",
	"secondaryLight": "#d7d7d7",
	"secondaryDark": "#8d8d8d",
	"textPrimary": "#4C4C4C",
	"textSecondary": "#000000",
	"neutralColor": "#c7c7c7",
	"neutralLight": "#e0e0e0",
	"neutralDark": "#aeaeae",
	"textNeutral": "#000000",
	"textWhite": "#ffffff",
	"mainBorderRadius": "4px",
	"mapPopupWidth": "500px",
	"infoIconColor": "#abcdef",
	"typeIconColor": "#000000",
	"multipleAppearanceColor": "#ffff00",
	"highlightColor": "#fea500",
	"mapPopUpInteraction": "50px",
	"linkHighlight": "#999999",
	"helpMain": "#b2dfdb",
	"helpLight": "#e5ffff",
	"helpDark": "#82ada9",
	"timelineHighlight": "#000000"
};
export default ___CSS_LOADER_EXPORT___;
