import './NumberDisplay.scss';
import styles from '../../styles/global.scss';
// MUI components
import Typography from '@mui/material/Typography';

export default function NumberDisplay(props) {

    return (
        <div className='number-wrapper'>
            <Typography
            lineHeight={1}
            color={styles.textMedium}
            fontSize={styles.fontLarge}
            >{props.amount?.toLocaleString('de-CH')}</Typography>
            {props.subheader? (
                <Typography
                lineHeight={1}
                color={styles.textMedium}
                fontSize={styles.fontXXSmall}
                >{props.subheader}</Typography>
            ):null}
            <Typography
            lineHeight={1}
            color={styles.textMedium}
            fontSize={styles.fontXSmall}
            >{props.name}</Typography>
        </div>
    );
}