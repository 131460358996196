import './ConflictExplorer.scss';
import { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import styles from './styles/global_variables.scss';
// API functions
import { 
  getFullActors, 
  getEventTypeColours, 
  updateEventTypeColours,
  getLinkTypeColours,
  updateLinkTypeColours,
  } from './utils/queryBackend';
// MUI components
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Exploration from './views/Exploration/Exploration';
// utils
import {ActorPool} from './utils/Actor/ActorPool';

const theme = createTheme({
// if you want to update palette, do so in styles/global_variables.scss
palette: {
  primary: {
    main: styles.primaryColor,
    dark: styles.primaryLight,
    light: styles.primaryLight,
    contrastText: styles.textPrimary,
  },
  secondary: {
    main: styles.secondaryColor,
    dark: styles.secondaryDark,
    light: styles.secondaryLight,
    contrastText: styles.textSecondary,
  },
  neutral: {
    main: styles.neutralColor,
    dark: styles.neutralLight,
    light: styles.neutralDark,
    contrastText: styles.textNeutral,
  },
  text: {
    main: styles.textPrimary,
    dark: styles.textNeutral,
    light: styles.textSecondary,
    contrastText: styles.textWhite,
  },
  help: {
    main: styles.helpMain,
    dark: styles.helpDark,
    light: styles.helpLight,
    contrastText: styles.textNeutral,
  }
},
});

export default function ConflictExplorer(props) {
  const { promiseInProgress } = usePromiseTracker();
    const [haveActors, setHaveActors] = useState(false);
    const [actors, setActors] = useState([]);
    const [actorNames, setActorNames] = useState([]);
    const [eventTypeColours, setEventTypeColours] = useState(null);
    const [linkTypeColours, setLinkTypeColours] = useState(null);
    const [requestedLinkTypeColours, setRequestedLinkTypeColours] = useState(false);
    const [requestedEventTypeColours, setRequestedEventTypeColours] = useState(false);
    const [needFetching, setNeedFetching] = useState(false);
    const [labelSize, setLabelSize] = useState('14pt');
    const [linkWidth, setLinkWidth] = useState(50);

    useEffect(() => {
      // prevents API call from being executed twice
      if (needFetching) return;
      setNeedFetching(true);
    });
  
    useEffect(()=>{
      if (!needFetching) return;

      if(!haveActors){
        setHaveActors(true)
        getAllActors();
      } 
      if(!eventTypeColours && !requestedEventTypeColours){
        setRequestedEventTypeColours(true);
        getEventTypeColours('?').then(responseData => {
          setEventTypeColours(responseData)
        })
      }
      if(!linkTypeColours && !requestedLinkTypeColours){
        setRequestedLinkTypeColours(true)
        getLinkTypeColours('?').then(responseData => {
          setLinkTypeColours(responseData)
        })
      }
    }, [needFetching])
  
    function getAllActors(){
      if(props.gw_number){
        trackPromise(getFullActors(`?gw_number=${props.gw_number}`)).then(responseData => {
          const actorPool = new ActorPool();
          responseData.forEach(actor => {
            actorPool.addActor(actor);
          })
          setActors(actorPool.getActors());
          var names = actorPool.getActors().map(actor => actor.getName());
          names.sort();
          setActorNames(names);
        })
      }
    }
  
    function applyChangeEventTypeColours(newColours){
      updateEventTypeColours(newColours).then(response => {
          setEventTypeColours(response)
      })
    }

    function applyChangeGraphSettings(newColours, newLabelFontSize, newLinkWidth){
      updateLinkTypeColours(newColours).then(response => {
          setLinkTypeColours(response)
      })
      setLabelSize(newLabelFontSize);
      setLinkWidth(newLinkWidth);
    }

    return (
        <div className='conflict-explorer'>
          <ThemeProvider theme={theme}>
            <Backdrop
            sx={{ color: '#fff', zIndex: 1000 }}
            open={promiseInProgress}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <Exploration 
            countryName={props.countryName}
            gw_number={props.gw_number}
            actors={actors} 
            actorNames={actorNames} 
            eventTypeColours={eventTypeColours}
            applyChangeEventTypeColours={applyChangeEventTypeColours}
            linkTypeColours={linkTypeColours}
            applyChangeGraphSettings={applyChangeGraphSettings}
            labelSize={labelSize}
            linkWidth={linkWidth}
            />
          </ThemeProvider>
        </div>
    );
}