import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './styles/global.scss';
import { BrowserRouter as Router } from "react-router-dom";

import "inter-ui/inter.css";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: styles.darkPrimary,
    },
    secondary: {
      main: styles.lightPrimary,
    },
    neutral: {
      main: styles.lightSecondary,
    },
  },
  typography: {
    fontFamily: [
      'inter',
    ].join(','),
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: styles.textLight,
          backgroundColor: styles.mediumPrimary,
          fontSize: styles.fontXXSmall,
          fontWeight: 400,
          margin: 1,
          "&.Mui-selected": {
            color: styles.textLight,
            backgroundColor: styles.lightPrimary,
          },
          "&:hover": {
            color: styles.textLight,
            backgroundColor: styles.lightPrimary
          },
          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderRadius: styles.borderRadiusSmall,
          },
          '&.MuiToggleButtonGroup-grouped:first-of-type': {
            borderRadius: styles.borderRadiusSmall,
          },
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <Router>
      <App />
    </Router>
    </ThemeProvider>
  </React.StrictMode>
);

