import './AppDisplay.scss';
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// API Functions
import { hasFetchError } from '../../backend/queryBackend';
import { hasFetchErrorConflictExplorer } from '../../components/ConflictExplorer/utils/queryBackend';
// components
import PieceView from '../PeaceView/PeaceView';
import ConflictView from '../ConflictView/ConflictView';
import VisualisationView from '../VisualisationView/VisualisationView';
import PodcastView from '../PodcastView/PodcastView';
import DownloadView from '../DownloadView/DownloadView';
import AboutView from '../AboutView/AboutView';
import FetchErrorView from '../FetchErrorView/FetchErrorView';

console.log('hasFetchErrorConflictExplorer', hasFetchErrorConflictExplorer)

export default function AppDisplay(props) {
  const [showFetchError, setShowFetchError] = useState(false);

  useEffect(() => {
    setShowFetchError(hasFetchError | hasFetchErrorConflictExplorer);

  }, [hasFetchError, hasFetchErrorConflictExplorer])

  return (
    <div className="app-display">
      <div className='display-wrapper'>
        <Routes>
            <Route path="/" element={showFetchError? <FetchErrorView/>:<PieceView/>}/>
            <Route path="/peace-data" element={showFetchError? <FetchErrorView/>:<PieceView/>}/>
            <Route path="/conflict-networks" element={showFetchError? <FetchErrorView/>:<ConflictView/>}/>
            <Route path="/visualisations" element={showFetchError? <FetchErrorView/>:<VisualisationView/>}/>
            <Route path="/podcasts" element={showFetchError? <FetchErrorView/>:<PodcastView/>}/>
            <Route path="/download" element={showFetchError? <FetchErrorView/>:<DownloadView/>}/>
            <Route path="/about" element={showFetchError? <FetchErrorView/>:<AboutView/>}/>
        </Routes>
      </div>
    </div>
  );
}