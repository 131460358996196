import './LinkCard.scss';
import {useState, useEffect} from 'react';
import styles from '../../styles/global_variables.scss';
// MUI components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ActorIcon from '../ActorIcon/ActorIcon';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';
// components
import ActorCard from '../ActorCard/ActorCard';
import EventsCard from '../EventsCard/EventsCard';
// external libraries
import moment from 'moment';

export default function LinkCard(props) {
    const [eventsPeriodChecked, setEventsPeriodChecked] = useState(false);
    const [linkColour, setLinkColour] = useState('');
    const [linkStroke, setLinkStroke] = useState('');
    const [openActorCard, setOpenActorCard] = useState(false);
    const [selectedActor, setSelectedActor] = useState('');

    useEffect(() => {
        if(props.linkTypeColours && props.link){
            const linkType = ((props.link.linkType === 1)? 'opposition' : 'cooperation');
            setLinkColour(props.linkTypeColours[linkType]['colour'])
            setLinkStroke(props.linkTypeColours[linkType]['stroke'])
        }
    }, [props.linkTypeColours, props.link])

    const handleClose = (event) => {
        setEventsPeriodChecked(false);
        props.onClose();
    }

    function handleEventsPeriodChange(){
        setEventsPeriodChecked(!eventsPeriodChecked);
    }

    function getActor(name){
        name = name.trim()
        const found_by_name = props.actors.find(actor => (actor.getName() === name));
        if(!found_by_name){
            return null
        }
        else{
            return found_by_name
        }
    }

    function showActorCard(actor){
        setSelectedActor(actor.getName())
        setOpenActorCard(true)
    }

    function hideActorCard(){
        setOpenActorCard(false);
      };

    return (
        <div className='link-card-dialog-wrapper'>
            {getActor(selectedActor)? 
            <ActorCard
            gw_number={props.gw_number}
            open={openActorCard}
            onClose={hideActorCard}
            actor={getActor(selectedActor)}
            actorName={selectedActor}
            start={props.start}
            end={props.end}
            actors={props.actors}
            fullPeriod={false}
            eventTypeColours={props.eventTypeColours}
            >
            </ActorCard>
            :null}
            <Dialog 
            onClose={handleClose} 
            PaperProps={{ sx: { width: "90%", maxWidth: "100%", height: "100%" } }}
            open={props.open}
            >
                <DialogTitle sx={{paddingBottom: '0px'}} className='link-dialog-title'>
                    <div className='link-card-title-wrapper'>
                        <div className='link-title-actor'>
                            <ActorIcon dim={50} actor={props.actor1} colour={styles.typeIconColor} eventTypeColours={props.eventTypeColours}></ActorIcon>
                            <Typography fontSize='1rem' sx={{marginLeft: '5px', whiteSpace: 'nowrap', marginRight:'10px'}}>{props.actor1?.getName()}</Typography>
                            <IconButton
                            onClick={() => showActorCard(props.actor1)}
                            size='small'
                            >
                                <InfoIcon 
                                sx={{ color:'secondary'}}
                                fontSize='small'
                                ></InfoIcon>
                            </IconButton>
                        </div>
                        <div className='link-link-wrapper'>
                            <svg className='dash-icon'>
                                <line 
                                stroke={linkColour}
                                strokeDasharray={linkStroke}
                                x1='0'
                                y1='0'
                                x2='100%'
                                y2='0'
                                strokeWidth='10'
                                transform='translate(0,5)'
                                ></line>
                            </svg>
                        </div>
                        <div className='link-title-actor'>
                            <ActorIcon dim={50} actor={props.actor2} colour={styles.typeIconColor} eventTypeColours={props.eventTypeColours}></ActorIcon>
                            <Typography fontSize='1rem' sx={{marginLeft: '5px', whiteSpace: 'nowrap', marginRight:'10px'}}>{props.actor2?.getName()}</Typography>
                            <IconButton
                            onClick={() => showActorCard(props.actor2)}
                            size='small'
                            >
                                <InfoIcon 
                                sx={{ color:'secondary'}}
                                fontSize='small'
                                ></InfoIcon>
                            </IconButton>
                        </div>
                    </div>
                {props.onClose ? (
                <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
                >
                <CloseIcon />
                </IconButton>
            ) : null}
                </DialogTitle>
                <DialogContent>
                    <div className='event-card-wrapper'>
                        {!props.fullPeriod?<Stack direction="row" spacing={1} alignItems="center" sx={{paddingX:'12px'}}>
                            <Typography fontSize='0.8rem'>Period ({moment(props.start).format('DD.MM.YY')} - {moment(props.end).format('DD.MM.YY')})</Typography>
                            <Switch  
                            color='secondary'
                            size='small'
                            checked={eventsPeriodChecked}
                            onChange={() => handleEventsPeriodChange()}
                                />
                            <Typography fontSize='0.8rem'>Full</Typography>
                        </Stack>
                        :null}
                        <EventsCard
                        linkCardActors={true}
                        gw_number={props.gw_number}
                        full={eventsPeriodChecked}
                        actors={props.actors}
                        mainActors={[props.actor1, props.actor2]}
                        start={props.start}
                        end={props.end}
                        eventTypeColours={props.eventTypeColours}
                        link={props.link}
                        ></EventsCard>
                    </div>         
                </DialogContent>
            </Dialog>
        </div>
    );
}