import './LocationMap.scss';
import { useRef, useState, useEffect } from 'react';
import styles from '../../styles/global_variables.scss';
// API functions
import { getCentroidByGW, getMapOverlay } from '../../utils/queryBackend';
// components
import MapLegend from '../MapLegend/MapLegend';
import ActorCard from '../ActorCard/ActorCard';
import LocationEventPopup from '../LocationEventPopup/LocationEventPopup';
// utils
import mapboxAccess from '../../utils/mapboxAccess';
import getMapIcon from '../../utils/getMapIcon';
import addNoise from '../../utils/addNoise';
// external libraries
import { MapContainer, TileLayer, Marker, LayerGroup, useMap, GeoJSON } from 'react-leaflet';

export default function LocationMap(props) {
    const [openCard, setOpenCard] = useState(false);
    const [selectedActor, setSelectedActor] = useState('');
    const [colourSettings, setColourSettings] = useState(null);
    const [typeSelection, setTypeSelection] = useState(null);
    const [position, setPosition] = useState(null);
    const [overlayData, setOverlayData] = useState(null);
    const [needFetching, setNeedFetching] = useState(false);

    const initialPosition = [-2.619071, 26.118860] //need some initial coordinates
    const zoom = 6;
    const mapRef = useRef();

    const resizeMap = (mapRef) => {
        // make map fit into its container
        const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize())
        const container = document.getElementById('mapcontainer')
        if (container) {
            resizeObserver.observe(container)
        }
    }

    useEffect(() => {
        // prevents API call from being executed twice
        if (!props.gw_number || needFetching) return;
        setNeedFetching(true);
      }, [props.gw_number]);

    useEffect(()=>{
        if (!needFetching) return;

        if(props.gw_number){
            getCentroidByGW(`?gw_number=${props.gw_number}`).then(responseData => {
                const lat = responseData.latitude;
                const long = responseData.longitude;
                setPosition([lat, long])
            })
            getMapOverlay(`?gw_number=${props.gw_number}`).then(responseData => {
                try{
                    setOverlayData(JSON.parse(responseData))
                } catch {setOverlayData(responseData)}
            })
        }
    }, [needFetching])

    useEffect(() => {
        setColourSettings(props.eventTypeColours)
    }, [props.eventTypeColours])

    function showActorCard(actor){
        actor = actor.trim()
        setSelectedActor(actor)
        setOpenCard(true)
    }

    function hideActorCard(){
        setOpenCard(false);
    };

    function getColourForEvent(event){
        // get which colour belongs to the icon of this event
        var assignedColour = '#9c64a6';
        var assignedOpacity = 1;
        if(colourSettings){
            assignedColour = colourSettings[event.event_type]
        }
        if(typeSelection){
            if(typeSelection[event.event_type]){
                assignedOpacity = 1.0
            } else {
                assignedOpacity = 0
            }
        } 

        var markerOptions = {
            color: '#ffffff', 
            fillColor: assignedColour,
            fill: true,
            fillOpacity: assignedOpacity,
            weight:0.1,
        }
        return markerOptions;
    }

    function getActor(name){
        name = name.trim()
        const found_by_name = props.actors.find(actor => (actor.getName() === name));
        if(!found_by_name){
            return null
        }
        else{
            return found_by_name
        }
    }

    function setEventTypeFilter(newFilter){
        setTypeSelection(newFilter);
    }

    function showPopup(event){
        if(typeSelection){
            if(typeSelection[event.event_type]){
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    function RecenterAutomatically({centerPosition}){
        // function to recenter map upon reception of centroid coordinates
        const lat = centerPosition[0]
        const lng = centerPosition[1]
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, position);
        return null;
    }

    return(
        <div className='location-map-wrapper'>
            <div className='location-map-view'>
                {getActor(selectedActor)? 
                <ActorCard
                gw_number={props.gw_number}
                open={openCard}
                onClose={hideActorCard}
                actor={getActor(selectedActor)}
                actorName={selectedActor}
                start={props.start}
                end={props.end}
                actors={props.actors}
                fullPeriod={false}
                eventTypeColours={props.eventTypeColours}
                >
                </ActorCard>
                :null}
                <MapContainer 
                ref={mapRef} 
                id='mapcontainer' 
                className='map-container' 
                center={initialPosition} 
                zoom={zoom} 
                minZoom={2}
                scrollWheelZoom={false}
                whenReady={() => resizeMap(mapRef)}
                preferCanvas={true}
                worldCopyJump={true}
                >
                    {overlayData? 
                    <GeoJSON 
                    key='overlay-geojson'
                    data={overlayData}
                    style={{
                        fillColor: styles.neutralLight,
                        color: styles.neutralLight,
                        fillOpacity: 0.5
                    }}
                    ></GeoJSON>:null}
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url={`https://api.mapbox.com/styles/v1/peaceobservatory-dev/cllfq4h4s012e01pi2mml622q/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxAccess}`}
                    />
                    <LayerGroup>
                            {  props.events.map(event => {
                                return(
                                <Marker 
                                position={[addNoise(event?.latitude), addNoise(event?.longitude)]}
                                icon={getMapIcon(event?.interaction, getColourForEvent(event), 12)}
                                key={event?.id}
                                >
                                    {showPopup(event)? (<LocationEventPopup 
                                        event={event} 
                                        showActorCard={showActorCard} 
                                        actors={props.actors}
                                        actorNames={props.actorNames}
                                        markerColour={getColourForEvent(event).fillColor}
                                        eventTypeColours={props.eventTypeColours}
                                        ></LocationEventPopup>):null}
                                </Marker>)
                            })}
                        <MapLegend 
                        eventTypeColours={props.eventTypeColours}
                        setEventTypeFilter={setEventTypeFilter}
                        ></MapLegend>
                    </LayerGroup>
                    
                    {position? <RecenterAutomatically centerPosition={position}></RecenterAutomatically> :null}
                </MapContainer>
            </div>
        </div>
    );
}