import './ActorCard.scss';
import {useState, useEffect} from 'react';
// API functions
import {getPeriodInfo, getActorTimeline} from '../../utils/queryBackend';
// MUI components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import styles from '../../styles/global_variables.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
// components
import ActorSpider from './ActorSpider/ActorSpider';
import ActorTimeline from './ActorTimeline/ActorTimeline';
import ActorIcon from '../ActorIcon/ActorIcon';
import EventsCard from '../EventsCard/EventsCard';
import ActorNode from '../ActorNode/ActorNode';
// external libraries
import moment from 'moment';

export default function ActorCard(props) {
    const [actorInfo, setActorInfo] = useState(null);
    const [timelineInfo, setTimelineInfo] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [showFull, setShowFull] = useState(false);
    const [eventsPeriodChecked, setEventsPeriodChecked] = useState(false);
    const [relevantCollaborationsFull, setRelevantCollaborationsFull] = useState([]);
    const [relevantCollaborationsPeriod, setRelevantCollaborationsPeriod] = useState([]);
    const [displayCollaborations, setDisplayCollaborations] = useState([]); 
    const [relevantOppositionsFull, setRelevantOppositionsFull] = useState([]);
    const [relevantOppositionsPeriod, setRelevantOppositionsPeriod] = useState([]);
    const [displayOppositions, setDisplayOppositions] = useState([]); 


    useEffect(() =>{
        // whenever the card is opened, fetch necessary data from API
        if(props.open && props.gw_number){
            setActorInfo(null);
            queryActorInformation();
            getActorTimeline(`?id=${props.actor.id}&gw_number=${props.gw_number}`).then(responseData => {
                setTimelineInfo(responseData);
            })
        }
        if(props.fullPeriod){
            setShowFull(true);
        }
    }, [props.open])

    useEffect(() => {
        // logic for determining which opponents/collaborations to show depending on full data/period data selection
        // FULL
        var resFullCollabs = props.actors.filter(actor => (actor.getName() in props.actor.collaborations))
        resFullCollabs = resFullCollabs.sort((actorA, actorB) => {return (props.actor.collaborations[actorB.getName()] - props.actor.collaborations[actorA.getName()])})
        setRelevantCollaborationsFull(resFullCollabs)

        // Relevant for period
        var resPeriod = []
        if(actorInfo){
            resPeriod = props.actors.filter(actor => (actor.getName() in actorInfo.collaborations))
            resPeriod = resPeriod.sort((actorA, actorB) => {return (actorInfo.collaborations[actorB.getName()] - actorInfo.collaborations[actorA.getName()])})
            setRelevantCollaborationsPeriod(resPeriod)
        }

        if(showFull){
            setDisplayCollaborations(resFullCollabs)
        } else {
            setDisplayCollaborations(resPeriod)
        }

        // similar for oppositions
        // FULL
        var resFullOppos = props.actors.filter(actor => (actor.getName() in props.actor.oppositions))
        resFullOppos = resFullOppos.sort((actorA, actorB) => {return (props.actor.oppositions[actorB.getName()] - props.actor.oppositions[actorA.getName()])})

        setRelevantOppositionsFull(resFullOppos)
        
        // Relevant for period
        var resPeriodOppos = []
        if(actorInfo){
            resPeriodOppos = props.actors.filter(actor => (actor.getName() in actorInfo.oppositions))
            resPeriodOppos = resPeriodOppos.sort((actorA, actorB) => {return (actorInfo.oppositions[actorB.getName()] - actorInfo.oppositions[actorA.getName()])})
            setRelevantOppositionsPeriod(resPeriodOppos)
        }
        if(showFull){
            setDisplayOppositions(resFullOppos)
        } else {
            setDisplayOppositions(resPeriodOppos)
        }
    }, [props.actor, props.open, actorInfo])

    const handleClose = (event) => {
        // when card is closed, reset all settings 
        setTabValue(0);
        setShowFull(true);
        setEventsPeriodChecked(false);
        setActorInfo(null);
        props.onClose();
    }

    function queryActorInformation(){
        if(props.gw_number){
            getPeriodInfo(`?id=${props.actor.id}&start=${props.start}&end=${props.end}&gw_number=${props.gw_number}`).then((responseData) => {
                setActorInfo(responseData);
            })
        }
    }

    function handleTabChange(event, newValue) {
        setTabValue(newValue);
    };

    function handlePeriodChange(){
        // handle switch between period/full data display selection
        if(showFull){
            //now show period
            setDisplayCollaborations(relevantCollaborationsPeriod)
            setDisplayOppositions(relevantOppositionsPeriod)
        } else {
            // now show full
            setDisplayCollaborations(relevantCollaborationsFull)
            setDisplayOppositions(relevantOppositionsFull)
        }
        setShowFull(!showFull);
    }

    function handleEventsPeriodChange(){
        setEventsPeriodChecked(!eventsPeriodChecked);
    }

    function getRelevantEventCounts(name, type){
        if(type==='collabs'){
            if(showFull){
                return props.actor.collaborations[name]
            } else {
                return actorInfo.collaborations[name]
            }
        } else {
            if(showFull){
                return props.actor.oppositions[name]
            } else {
                return actorInfo.oppositions[name]
            }
        }
        
    }

    return (
        <div className='actor-card-dialog-wrapper'>
            {(actorInfo && timelineInfo)?(
            <Dialog 
            onClose={handleClose} 
            PaperProps={{ sx: { minWidth: "90%", height: "100%" } }}
            open={props.open}
            >
                <DialogTitle sx={{paddingBottom: '0px'}}>
                    <div className='actor-card-title-wrapper'>
                        <ActorIcon dim={60} actor={props.actor} colour={styles.typeIconColor} eventTypeColours={props.eventTypeColours}></ActorIcon>
                        <Typography fontSize='1.5rem' sx={{marginLeft: '5px', marginRight:'10px'}}>{props.actor.getName()}</Typography>
                    </div>
                    {props.onClose ? (
                    <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                    >
                        <CloseIcon />
                    </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                    <div className='actor-card-wrapper'>
                        <Tabs 
                        value={tabValue} 
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        >
                            <Tab 
                            label="Actor"  
                            id='actor-card-actor-tab' 
                            sx={{paddingY: '5px'}}
                            />
                            <Tab 
                            label="Events" 
                            id='actor-card-events-tab' 
                            sx={{paddingY: '5px'}}
                            />
                        </Tabs>
                        {tabValue === 0?(
                            <div className='actor-card-tab-wrapper'>
                                <div className='actor-card-actor-wrapper'>
                                    <div className='actor-card-actor-info'>
                                        <div className='actor-card-stats-wrapper'>
                                            <ActorSpider full={props.fullPeriod} event_counts_period={actorInfo.event_counts_period} event_counts_full={props.actor.eventTypeSummary} eventTypeColours={props.eventTypeColours}></ActorSpider>
                                        </div>
                                        <div className='actor-card-interaction-wrapper'>
                                            <div className='actor-card-interaction-title'>
                                                <Typography fontSize='0.8rem' fontWeight='bold' paddingX='5px'>Collaborations</Typography>
                                                {!props.fullPeriod?
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography fontSize='0.8rem'>Period ({moment(props.start).format('DD.MM.YY')} - {moment(props.end).format('DD.MM.YY')})</Typography>
                                                    <Switch  
                                                    color='secondary'
                                                    size='small'
                                                    checked={showFull}
                                                    onChange={handlePeriodChange}
                                                        />
                                                    <Typography fontSize='0.8rem'>Full</Typography>
                                                </Stack>
                                                :null}
                                                <Typography fontSize='0.8rem' fontWeight='bold' paddingX='5px'>Oppositions</Typography>
                                            </div>
                                            <div className='actor-card-both-interactions'>
                                                <div className='actor-card-interaction'>
                                                    {displayCollaborations.map(actor => {
                                                        return (
                                                            <ActorNode 
                                                            gw_number={props.gw_number}
                                                            key={'actor-item-' + actor['id']} 
                                                            className='home-actor-item'
                                                            actor={actor}
                                                            actors={props.actors}
                                                            start={props.start}
                                                            end={props.end}
                                                            displayNumber={getRelevantEventCounts(actor.getName(), 'collabs')}
                                                            fullPeriod={props.fullPeriod}
                                                            eventTypeColours={props.eventTypeColours}
                                                            >
                                                            </ActorNode>
                                                        )
                                                    })}
                                                </div>
                                                <div className='actor-card-interaction right'>
                                                    {displayOppositions.map(actor => {
                                                        return (
                                                            <ActorNode 
                                                            gw_number={props.gw_number}
                                                            key={'actor-item-' + actor['id']} 
                                                            className='home-actor-item'
                                                            actor={actor}
                                                            actors={props.actors}
                                                            start={props.start}
                                                            end={props.end}
                                                            displayNumber={getRelevantEventCounts(actor.getName(), 'oppos')}
                                                            fullPeriod={props.fullPeriod}
                                                            eventTypeColours={props.eventTypeColours}
                                                            >
                                                            </ActorNode>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='actor-card-actor-info'>
                                        <div className='actor-card-num-events-wrapper'>
                                            <Typography fontSize='1rem'>Events</Typography> 
                                            <div className='actor-card-num-events'>
                                                {!props.fullPeriod?
                                                <div className='actor-card-num-item period'>
                                                    <Typography fontSize='0.8rem'>In selected Period</Typography> 
                                                    <Typography fontSize='4rem' marginRight='0.8rem' lineHeight='1'>{actorInfo.period_entries}</Typography>
                                                    <div className='actor-dates'>
                                                        <Typography fontSize='0.8rem'>last: {actorInfo.period_newest}</Typography>
                                                    </div>
                                                </div>
                                                :null}
                                                <div className='actor-card-num-item'>
                                                    <Typography fontSize='0.8rem' sx={{color: styles.neutralDark}}>Overall</Typography>
                                                    <div className='actor-num-events'>
                                                        <Typography fontSize='4rem' lineHeight='1' marginRight='0.8rem' sx={{color: styles.neutralDark}}>{props.actor.eventReportIds.length}</Typography>
                                                        <div className='actor-dates'>
                                                            <Typography fontSize='0.8rem' sx={{color: styles.neutralDark}}>last: {actorInfo.overall_newest}</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ActorTimeline start={props.start} end={props.end} timelineInfo={timelineInfo}></ActorTimeline>
                                    </div>
                                </div>
                            </div>)
                        :null
                        }
                        {tabValue === 1?(
                            <div className='actor-card-tab-wrapper'>
                                {!props.fullPeriod?<Stack direction="row" spacing={1} alignItems="center">
                                    <Typography fontSize='0.8rem'>Period ({moment(props.start).format('DD.MM.YY')} - {moment(props.end).format('DD.MM.YY')})</Typography>
                                    <Switch  
                                    color='secondary'
                                    size='small'
                                    checked={eventsPeriodChecked}
                                    onChange={handleEventsPeriodChange}
                                        />
                                    <Typography fontSize='0.8rem'>Full</Typography>
                                </Stack>
                                :null}
                                <EventsCard
                                gw_number={props.gw_number}
                                full={eventsPeriodChecked}
                                actors={props.actors}
                                mainActors={[props.actor]}
                                start={props.start}
                                end={props.end}
                                eventTypeColours={props.eventTypeColours}
                                ></EventsCard>
                            </div>
                        ):null}       
                    </div>         
                </DialogContent>
            </Dialog>
            ):null}
        </div>
    );
}