import './SearchBox.scss';
import styles from '../../styles/global.scss';
// MUI components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
// external libraries
import { matchSorter } from 'match-sorter'; // resource: https://github.com/kentcdodds/match-sorter, for filtering loosely

const StyledAutocomplete = styled(Autocomplete)({
  //https://stackoverflow.com/questions/58984406/setting-text-color-outline-and-padding-on-material-ui-autocomplete-component
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: styles.textLight, //label text that moves above input (focused)
    borderColor: styles.textLight
  },
  "& .MuiInputLabel-outlined": {
    color: styles.textLight, //label text that moves above input (no focus)
  },
  "& .MuiAutocomplete-inputRoot": {
    color: styles.textLight, // selected option text (inside textfield)
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: styles.textLight, //unfocused outline
    },
  },
  "& .MuiSvgIcon-root": {
    color: styles.textLight
  }
});


function sortItems(items){
  // sort first by type, then alphabetically by name
  items.sort((a, b) => {
    if(a.item.type === b.item.type){
      return a.item.name.localeCompare(b.item.name)
    } else {
      return a.item.type > b.item.type
    }
  }
  )
  return items
}

export default function SearchBox(props) {
  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {keys: props.optionsKeys, sorter: rankedItems => sortItems(rankedItems)});

  function getNavigationWidth(){
    // makes sure the search box aligns with the navigation tabs
    var element = document.getElementById('navigation-wrapper');
    let width = '50%';
    if(element){
      var positionInfo = element.getBoundingClientRect();
      width = positionInfo.width + 'px';
    }
    return width 
  }

  return (
    <StyledAutocomplete
      disablePortal
      id="text-search-box"
      options={props.options}
      getOptionDisabled={option => option.id === 0}
      size="small"
      groupBy={(option) => option.type}
      sx={{ width: getNavigationWidth}}
      className='search-box'
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option[props.identification] + (option['type']==='dyad'? ` (${option.id}) `:''))}
      renderInput={(params) => <TextField {...params} label="search" />}
      onChange={(event, newValue) => {
        props.handleSelect(newValue)
      }}
    />
  );
}