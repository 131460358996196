import './ActorTimeline.scss';
import {useEffect, useRef} from 'react';
import styles from '../../../styles/global_variables.scss';
// external libraries
import * as d3 from "d3";
import moment from 'moment';


export default function ActorTimeline(props) {
    const timelineRef = useRef();
    const timelineWrapperRef = useRef();

    function mouseover(event, key, val){
        // delete any remaining tooltips
        d3.select("#TimelineTooltipID").remove();
        // add tooltips to drawing
        d3.select(timelineWrapperRef.current)
            .append("div")
            .style("position", "absolute")
            .style("opacity", 0)
            .attr("class", "timelineTooltip")
            .attr("id", "TimelineTooltipID")
            .style("background-color", styles.neutralColor)
            .style("border-radius", styles.mainBorderRadius)
            .style("padding", "5px")
    }

    function mousemove(event, date, val){
        // update tooltip with its text
        var dateFormatted = new Date(date);
        var options = { year: 'numeric', month: 'long'};
        dateFormatted = dateFormatted.toLocaleDateString("en-GB", options)
        d3.select("#TimelineTooltipID")
            .style("opacity", 1)
            .html(
                "<div className=timelineTooltip>" +
                    `<div className=line> ${val} (${dateFormatted})</div>` +
                "</div>"
            )
            .style("left", (event.x) - 200 + "px")
            .style("top", (event.y) - 65 + "px")
            .style('font-size', '0.8rem')
    }

    function mouseleave(event, key, val){
        // hide tooltip again
        d3.select("#TimelineTooltipID")
            .style("opacity", 0)
        // remove the tooltip when hover leaves point
        d3.select("#TimelineTooltipID").remove();
    }

    useEffect(() => {

        if(props.timelineInfo){
            const data = props.timelineInfo.map(entry => {
                return {
                    'date': d3.timeParse("%Y-%m")(`${entry['year']}-${entry['month']}`), 
                    'value': entry['value']
                }
            });

            var svg = d3.select(timelineRef.current);

            // remove all leftover elements
            svg.selectAll("g").remove();
            svg.selectAll("path").remove();
            svg.selectAll("circle").remove();

            var svgWidth = 200;
            var svgHeight = 400;
            const graphPadding = 30;
            const yPadding = 5;
            
            const vals = (data.map((entry) => {return entry['value']}))
            const maxEntry = Math.max(...vals);

            if(timelineRef.current){
                const svgSize = timelineRef.current.getBoundingClientRect();
                svgWidth = svgSize.width;
                svgHeight = svgSize.height;
            }

            // add X - Axis
            let timelineScale = d3.scaleTime()
                .domain(d3.extent(data, d => d.date))
                .range([ 0, svgWidth-graphPadding - yPadding ]);

            let xAxis = d3.axisBottom(timelineScale)

            svg.append("g")
                .attr("transform", `translate(${graphPadding},${svgHeight - graphPadding + yPadding})`)
                .call(xAxis);

            // add Y-Axis
            let yAxisScale = d3.scaleLinear()
                .domain( [0, maxEntry + 2])
                .range([ svgHeight - graphPadding, 0 ]);

            let yAxis = d3.axisLeft(yAxisScale)

            svg.append("g")
                .attr("transform", `translate(${graphPadding},${yPadding})`)
                .call(yAxis);

            // function to distinguish data inside from data outside period
            function getDateColour(d){
                const startDate = moment(props.start, 'YYYY-MM-DD').startOf('month'); // extend period to start of start month
                const endDate = moment(props.end, 'YYYY-MM-DD').endOf('month'); // extend period to end of end month
                if(moment(d.date, 'YYYY-M').second(10).isBetween(startDate, endDate)){// need to add some time to the queried date because isBetween is exclusive
                    return styles.timelineHighlight;
                } else {
                    return styles.neutralDark;
                }
            }

            const barWidth = ((svgWidth-graphPadding - yPadding) / data.length)

            // add the bars
            svg.selectAll("rect")
            .data(data)
            .join("rect")
            .attr("x", d => graphPadding + timelineScale(d.date))
            .attr("y", d => yAxisScale(d.value) + yPadding)
            .attr("width", barWidth)
            .attr("height", d => (d.value ===0)? 0:svgHeight  - graphPadding - (yAxisScale(d.value)))
            .attr("fill", d => getDateColour(d))
            .style('cursor', 'pointer')
            .on("mouseover", (event, d) => mouseover(event, d.date, d.value))
            .on("mousemove", (event, d) => mousemove(event, d.date, d.value))
            .on("mouseleave", (event, d) => mouseleave(event, d.date, d.value));
        }

    })

    return (
        <div ref={timelineWrapperRef} className='actor-card-timeline-wrapper'>
            <svg className='actor-card-timeline-svg' ref={timelineRef}></svg>
        </div>
    );
}