import AppBar from '@mui/material/AppBar';
import './PeriodSelectionBar.scss';
import { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import styles from '../../styles/global_variables.scss';
// API functions
import { getAvailableDates } from '../../utils/queryBackend';
// MUI components
import Toolbar from '@mui/material/Toolbar';
import FormControl from '@mui/material/FormControl';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// components
import PeriodTimePicker from './PeriodTimePicker/PeriodTimePicker';
import ColourSettings from '../ColourSettings/ColourSettings';
import TypeIcon from '../TypeIcon/TypeIcon';
// utils
import interCodeMapping from '../../utils/interCodeMapping';
// external libraries
import moment from 'moment';

export default function PeriodSelectionBar(props) {
    const [timeStart, setTimeStart] = useState(moment(props.initialStart, 'YYYY-MM-DD'));
    const [timeEnd, setTimeEnd] = useState(moment(props.initialEnd, 'YYYY-MM-DD'));
    const [availableDays, setAvailableDays] = useState([]);
    const [isInvalid, setIsInvalid] = useState(false);
    const [needFetching, setNeedFetching] = useState(false);


    useEffect(() => {
        // prevents API call from being executed twice
        if (!props.gw_number || needFetching) return;
        setNeedFetching(true);
      }, [props.gw_number]);

    useEffect(() => { 
        if (!needFetching) return;
        
        if(props.gw_number && props.loadDates){
            trackPromise(getAvailableDates(`?gw_number=${props.gw_number}`)).then(
                (responseData) => {
                    let res = JSON.parse(responseData)
                    setAvailableDays(res)
                    props.setLoadDates(false)
                    }
                );
        }
     },[needFetching, props.loadDates]);

    
    const handleStartChange = (event) => {
        const newStart = moment(event).startOf('day');
        setTimeStart(newStart);
    };   
    
    const handleEndChange = (event) => {
        const newEnd = moment(event).endOf('day');
        setTimeEnd(newEnd);
    }; 

    function applyChanges(){
        props.getByDate(timeStart, timeEnd);
    }

    useEffect(() => {
        if(timeEnd < timeStart){
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
        }
    }, [timeStart, timeEnd]);

    return (
        <AppBar position='static' color='primary' className='period-selection-wrapper' elevation={0}>
            <Toolbar variant="dense" className='period-selection-toolbar'>
                <div className='period-selection-items'>
                    <div className='time-start-selector'>
                        <FormControl 
                        sx={{ m: 1, ml:2 }} 
                        size="small"
                        color='secondary'
                        className='period-selector date'
                        >
                            <Typography 
                            variant="button" 
                            display="block"
                            mr={0.5} 
                            fontSize='0.8rem'
                            >start</Typography>
                            <PeriodTimePicker
                            initialTime={timeStart}
                            availableDays={availableDays}
                            handleDateChange={handleStartChange}
                            kind={'start'}
                            ></PeriodTimePicker>
                        </FormControl>
                    </div>
                    <div className='time-end-selector'>
                        <FormControl 
                        sx={{ m: 1, ml:2 }} 
                        size="small"
                        color='secondary'
                        className='period-selector date'
                        >
                            <Typography 
                            variant="button" 
                            display="block" 
                            mr={0.5} 
                            fontSize='0.8rem'
                            >end</Typography>
                            <PeriodTimePicker
                            initialTime={timeEnd}
                            availableDays={availableDays}
                            handleDateChange={handleEndChange}
                            kind={'end'}
                            ></PeriodTimePicker>
                        </FormControl>
                    </div>
                    <Button
                    id="apply-period"
                    variant="contained"
                    color="neutral"
                    disableElevation
                    onClick={applyChanges}
                    sx={{ml: 2, py: 0, fontSize:'0.8rem'}}
                    size='small'
                    disabled={isInvalid}
                    >
                        Apply
                    </Button>
                    { isInvalid?
                        <div className='warning-mismatch'>
                            <WarningAmberIcon color='secondary'></WarningAmberIcon>
                            <Typography color='secondary'>Start date is after end date</Typography>
                        </div>
                        :null
                    }
                </div>
                <div className='period-end-items'>
                    <div className='period-colour-settings'>
                        <ColourSettings
                        eventTypeColours={props.eventTypeColours}
                        applyChangeEventTypeColours={props.applyChangeEventTypeColours}
                        linkTypeColours={props.linkTypeColours}
                        applyChangeGraphSettings={props.applyChangeGraphSettings}
                        ></ColourSettings>
                    </div>
                    <Button 
                        onClick={() => props.setStepsEnabled(true)}
                        variant='contained'
                        color='help'
                        size='small'
                        disableElevation
                        sx={{ml: 2, py: 0, fontSize:'0.8rem', whiteSpace: 'nowrap' }} 
                        >
                            Help
                        </Button>
                </div>
            </Toolbar>
            <div className='actor-interaction-legend'>
                {Object.keys(interCodeMapping).map((code) => {
                const numCode = Number(code)
                if(numCode !== 0 && numCode !== 3){
                return(
                    <div 
                    className='interaction-legend-item'
                    key={'filter-type-' + numCode}>
                        <TypeIcon
                        type={numCode}
                        dim={14}
                        color={styles.neutralDark}
                        ></TypeIcon>
                        <Typography sx={{marginX: '4px', textAlign:'center', marginY:'1px'}} fontSize='0.6rem' >{interCodeMapping[numCode]}</Typography>
                    </div> 
                )} else { return null; }
                })}
            </div>
        </AppBar>
    );
}