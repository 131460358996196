import './ColourSettings.scss';
import { useState } from 'react';
// MUI components
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// components
import OverallColourDialog from './OverallColourDialog/OverallColourDialog';

export default function ColourSettings(props){
    const [open, setOpen] = useState(false);

    function showColourDialog() {
        setOpen(true)
    }

    function hideColourDialog(){
        setOpen(false);
    };

    return (
        <FormGroup className='colour-setting-wrapper'>
            <OverallColourDialog
                open={open}
                onClose={hideColourDialog}
                eventTypeColours={props.eventTypeColours}
                applyChangeEventTypeColours={props.applyChangeEventTypeColours}
                linkTypeColours={props.linkTypeColours}
                applyChangeGraphSettings={props.applyChangeGraphSettings}
            />
            <Button
            id="colour-settings"
            variant="contained"
            color="neutral"
            disableElevation
            sx={{ml: 2, py: 0, fontSize:'0.8rem', whiteSpace: 'nowrap' }} 
            size='small'
            onClick={showColourDialog}
            endIcon={<AutoFixHighIcon />}
            >
                Display Settings
            </Button>
        </FormGroup>
    )
}
