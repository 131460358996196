import './DyadView.scss';
import { useState, useEffect } from "react";
import styles from '../../styles/global.scss';
// API functions
import { 
    getDyadText, 
    getPeaceData,
    getFileForDyadId
 } from '../../backend/queryBackend';
// MUI components
import Typography from '@mui/material/Typography';
// components
import NegotiationItem from '../NegotiationItem/NegotiationItem';
import BackButton from '../BackButton/BackButton';
import ReducedDownloadItem from '../ReducedDownloadItem/ReducedDownloadItem';

export default function DyadView(props) {
    const [dyadText, setDyadText] = useState('');
    const [peaceData, setPeaceData] = useState([]);
    const [needFetching, setNeedFetching] = useState(false);
    const [relevantDownload, setRelevantDownload] = useState();

    useEffect(() => {
        // prevents API call from being executed twice
        if (needFetching) return;
        setNeedFetching(true);
      }, [props.selectedDyad.id]);

    useEffect(() => {
        if(!needFetching) return;
        
        getDyadText(`?dyad_id=${props.selectedDyad.id}`).then(responseData => {
            setDyadText(responseData)
        })
        getPeaceData(`?dyad_id=${props.selectedDyad.id}`).then(responseData => {
            setPeaceData(responseData)
        })
        getFileForDyadId(`?dyad_id=${props.selectedDyad.id}`).then((responseData) => {
            setRelevantDownload(responseData) 
      })
    }, [needFetching])

    function resetSearch(){
        // upon clicking on back button, unset selected dyad
        props.setSelectedDyad(null)
    }

    function checkRecordsData(negotiations){

        if((negotiations.length === 1) && (negotiations[0]['description'].includes('does not record any negotiations'))){
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className='dyad-view'>
            <div className='dyad-header'>
                <div className='header-main'>
                    <BackButton 
                    className='button-wrapper' 
                    searchParamName={props.searchParamName}
                    resetSearch={resetSearch}
                    buttonText={props.hasCountry?'country view':'world view'}></BackButton>
                    <Typography
                    className='title'
                    color={styles.textMedium}
                    fontSize={styles.fontMedium}
                    >{props.selectedDyad.name}
                    </Typography>
                    <div className='right'></div>
                </div>
                <div className='download-wrap right'>
                {(relevantDownload && (relevantDownload.filename != 'not found'))?
                    <ReducedDownloadItem 
                    folder='summaries'
                    key={'file-download-' + relevantDownload.filename}
                    item={relevantDownload}>
                    </ReducedDownloadItem>:null}
                </div>
            </div>
            <div className='dyad-content'>
                <div className='background-wrapper'>
                    <Typography
                    className='background-text'
                    color={styles.textMedium}
                    fontSize={styles.fontXSmall}
                    my={1}
                    >
                        {dyadText}
                    </Typography>
                </div>
                {checkRecordsData(peaceData)?
                <div className='dyad-table-wrapper'>
                    <div className='dyad-table-header-wrapper'>
                        {(peaceData.length>0)?
                            <div className='dyad-table-header'>
                                <Typography
                                color={styles.textMedium}
                                fontSize={styles.fontXXSmall}
                                fontWeight='bold'
                                >ID</Typography>
                                <Typography
                                color={styles.textMedium}
                                fontSize={styles.fontXXSmall}
                                fontWeight='bold'
                                >start</Typography>
                                <Typography
                                color={styles.textMedium}
                                fontSize={styles.fontXXSmall}
                                fontWeight='bold'
                                >end</Typography>
                                <Typography
                                color={styles.textMedium}
                                fontSize={styles.fontXXSmall}
                                fontWeight='bold'
                                >location of negotiations</Typography>
                                <Typography
                                color={styles.textMedium}
                                fontSize={styles.fontXXSmall}
                                fontWeight='bold'
                                >mediation</Typography>
                                <Typography
                                color={styles.textMedium}
                                fontSize={styles.fontXXSmall}
                                fontWeight='bold'
                                >agreement type</Typography>   
                            </div>:null
                        }
                        <div className='icon-placeholder'></div>
                    </div>
                    <div className='dyad-table'>
                        {peaceData.map((item, idx) => {
                            return(
                                <NegotiationItem
                                item={item}
                                key={`negotiation-item-` + idx}
                                ></NegotiationItem>
                            )
                        })}
                    </div>
                </div>
                :
                <div className='no-data-wrapper'>
                    <Typography
                    fontStyle={'italic'}
                    color={styles.textLight}
                    >The Peace Observatory does not record any negotiations or mediation in this armed conflict.</Typography>
                </div>
                }
            </div>
        </div>
    );
}