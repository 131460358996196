import blob from '../assets/icons/blob_icon.js';
import circle from '../assets/icons/circle_icon.js';
import cross from '../assets/icons/cross_icon.js';
import diamond from '../assets/icons/diamond_icon.js';
import fence from '../assets/icons/fence_icon.js';
import multistar from '../assets/icons/multistar_icon.js';
import spike from '../assets/icons/spike_icon.js';
import square from '../assets/icons/square_icon.js';
import sun from '../assets/icons/sun_icon.js';

import blob_circle from '../assets/icons/combined_icons/blob_circle.js';
import blob_cross from '../assets/icons/combined_icons/blob_cross.js';
import blob_diamond from '../assets/icons/combined_icons/blob_diamond.js';
import blob_fence from '../assets/icons/combined_icons/blob_fence.js';
import blob_multistar from '../assets/icons/combined_icons/blob_multistar.js';
import blob_spike from '../assets/icons/combined_icons/blob_spike.js';
import blob_square from '../assets/icons/combined_icons/blob_square.js';
import blob_sun from '../assets/icons/combined_icons/blob_sun.js';

import circle_blob from '../assets/icons/combined_icons/circle_blob.js';
import circle_cross from '../assets/icons/combined_icons/circle_cross.js';
import circle_diamond from '../assets/icons/combined_icons/circle_diamond.js';
import circle_fence from '../assets/icons/combined_icons/circle_fence.js';
import circle_multistar from '../assets/icons/combined_icons/circle_multistar.js';
import circle_spike from '../assets/icons/combined_icons/circle_spike.js';
import circle_square from '../assets/icons/combined_icons/circle_square.js';
import circle_sun from '../assets/icons/combined_icons/circle_sun.js';

import cross_blob from '../assets/icons/combined_icons/cross_blob.js';
import cross_circle from '../assets/icons/combined_icons/cross_circle.js';
import cross_diamond from '../assets/icons/combined_icons/cross_diamond.js';
import cross_fence from '../assets/icons/combined_icons/cross_fence.js';
import cross_multistar from '../assets/icons/combined_icons/cross_multistar.js';
import cross_spike from '../assets/icons/combined_icons/cross_spike.js';
import cross_square from '../assets/icons/combined_icons/cross_square.js';
import cross_sun from '../assets/icons/combined_icons/cross_sun.js';

import diamond_blob from '../assets/icons/combined_icons/diamond_blob.js';
import diamond_circle from '../assets/icons/combined_icons/diamond_circle.js';
import diamond_cross from '../assets/icons/combined_icons/diamond_cross.js';
import diamond_fence from '../assets/icons/combined_icons/diamond_fence.js';
import diamond_multistar from '../assets/icons/combined_icons/diamond_multistar.js';
import diamond_spike from '../assets/icons/combined_icons/diamond_spike.js';
import diamond_square from '../assets/icons/combined_icons/diamond_square.js';
import diamond_sun from '../assets/icons/combined_icons/diamond_sun.js';

import fence_blob from '../assets/icons/combined_icons/fence_blob.js';
import fence_circle from '../assets/icons/combined_icons/fence_circle.js';
import fence_cross from '../assets/icons/combined_icons/fence_cross.js';
import fence_diamond from '../assets/icons/combined_icons/fence_diamond.js';
import fence_multistar from '../assets/icons/combined_icons/fence_multistar.js';
import fence_spike from '../assets/icons/combined_icons/fence_spike.js';
import fence_square from '../assets/icons/combined_icons/fence_square.js';
import fence_sun from '../assets/icons/combined_icons/fence_sun.js';

import multistar_blob from '../assets/icons/combined_icons/multistar_blob.js';
import multistar_circle from '../assets/icons/combined_icons/multistar_circle.js';
import multistar_cross from '../assets/icons/combined_icons/multistar_cross.js';
import multistar_diamond from '../assets/icons/combined_icons/multistar_diamond.js';
import multistar_fence from '../assets/icons/combined_icons/multistar_fence.js';
import multistar_spike from '../assets/icons/combined_icons/multistar_spike.js';
import multistar_square from '../assets/icons/combined_icons/multistar_square.js';
import multistar_sun from '../assets/icons/combined_icons/multistar_sun.js';

import spike_blob from '../assets/icons/combined_icons/spike_blob.js';
import spike_circle from '../assets/icons/combined_icons/spike_circle.js';
import spike_cross from '../assets/icons/combined_icons/spike_cross.js';
import spike_diamond from '../assets/icons/combined_icons/spike_diamond.js';
import spike_fence from '../assets/icons/combined_icons/spike_fence.js';
import spike_multistar from '../assets/icons/combined_icons/spike_multistar.js';
import spike_square from '../assets/icons/combined_icons/spike_square.js';
import spike_sun from '../assets/icons/combined_icons/spike_sun.js';

import square_blob from '../assets/icons/combined_icons/square_blob.js';
import square_circle from '../assets/icons/combined_icons/square_circle.js';
import square_cross from '../assets/icons/combined_icons/square_cross.js';
import square_diamond from '../assets/icons/combined_icons/square_diamond.js';
import square_fence from '../assets/icons/combined_icons/square_fence.js';
import square_multistar from '../assets/icons/combined_icons/square_multistar.js';
import square_spike from '../assets/icons/combined_icons/square_spike.js';
import square_sun from '../assets/icons/combined_icons/square_sun.js';

import sun_blob from '../assets/icons/combined_icons/sun_blob.js';
import sun_circle from '../assets/icons/combined_icons/sun_circle.js';
import sun_cross from '../assets/icons/combined_icons/sun_cross.js';
import sun_diamond from '../assets/icons/combined_icons/sun_diamond.js';
import sun_fence from '../assets/icons/combined_icons/sun_fence.js';
import sun_multistar from '../assets/icons/combined_icons/sun_multistar.js';
import sun_spike from '../assets/icons/combined_icons/sun_spike.js';
import sun_square from '../assets/icons/combined_icons/sun_square.js';

const nameToPath = {
    'blob': blob,
    'circle': circle,
    'cross': cross,
    'diamond': diamond,
    'fence': fence,
    'multistar': multistar,
    'spike': spike,
    'square': square,
    'sun': sun,

    'blob_circle': blob_circle,
    'blob_cross': blob_cross,
    'blob_diamond': blob_diamond,
    'blob_fence': blob_fence,
    'blob_multistar': blob_multistar,
    'blob_spike': blob_spike,
    'blob_square': blob_square,
    'blob_sun': blob_sun,

    'circle_blob': circle_blob,
    'circle_cross': circle_cross,
    'circle_diamond': circle_diamond,
    'circle_fence': circle_fence,
    'circle_multistar': circle_multistar,
    'circle_spike': circle_spike,
    'circle_square': circle_square,
    'circle_sun': circle_sun,

    'cross_blob': cross_blob,
    'cross_circle': cross_circle,
    'cross_diamond': cross_diamond,
    'cross_fence': cross_fence,
    'cross_multistar': cross_multistar,
    'cross_spike': cross_spike,
    'cross_square': cross_square,
    'cross_sun': cross_sun,

    'diamond_blob': diamond_blob,
    'diamond_circle': diamond_circle,
    'diamond_cross': diamond_cross,
    'diamond_fence': diamond_fence,
    'diamond_multistar': diamond_multistar,
    'diamond_spike': diamond_spike,
    'diamond_square': diamond_square,
    'diamond_sun': diamond_sun,

    'fence_blob': fence_blob,
    'fence_circle': fence_circle,
    'fence_cross': fence_cross,
    'fence_diamond': fence_diamond,
    'fence_multistar': fence_multistar,
    'fence_spike': fence_spike,
    'fence_square': fence_square,
    'fence_sun': fence_sun,

    'multistar_blob': multistar_blob,
    'multistar_circle': multistar_circle,
    'multistar_cross': multistar_cross,
    'multistar_diamond': multistar_diamond,
    'multistar_fence': multistar_fence,
    'multistar_spike': multistar_spike,
    'multistar_square': multistar_square,
    'multistar_sun': multistar_sun,

    'spike_blob': spike_blob,
    'spike_circle': spike_circle,
    'spike_cross': spike_cross,
    'spike_diamond': spike_diamond,
    'spike_fence': spike_fence,
    'spike_multistar': spike_multistar,
    'spike_square': spike_square,
    'spike_sun': spike_sun,

    'square_blob': square_blob,
    'square_circle': square_circle,
    'square_cross': square_cross,
    'square_diamond': square_diamond,
    'square_fence': square_fence,
    'square_multistar': square_multistar,
    'square_spike': square_spike,
    'square_sun': square_sun,

    'sun_blob': sun_blob,
    'sun_circle': sun_circle,
    'sun_cross': sun_cross,
    'sun_diamond': sun_diamond,
    'sun_fence': sun_fence,
    'sun_multistar': sun_multistar,
    'sun_spike': sun_spike,
    'sun_square': sun_square,
}

const codeToIconName = {
    0: '',
    1: 'multistar',
    2: 'cross', 
    3: 'cross', 
    4: 'diamond', 
    5: 'fence', 
    6: 'sun', 
    7: 'spike', 
    8: 'square', 
    9: 'blob', 
}


function interactionTojs(interaction){
    interaction = Number(interaction)
    const inter1 = Math.floor(interaction / 10) % 10
    const inter2 = interaction % 10

    const name1 = codeToIconName[inter1]
    const name2 = codeToIconName[inter2]

    var icon_name

    if(inter2 !== 0){
        if(inter1 === inter2){
            icon_name = name1
        } else {
            icon_name = name1 + '_' + name2
        }
    } else {
        icon_name = name1
    }
    return(nameToPath[icon_name])
}


export default interactionTojs
