import './SplitWindow.scss';
// components
import LocationMap from '../LocationMap/LocationMap'
import LocationGraph from '../LocationGraph/LocationGraph'

export default function SplitWindow(props) {

    const loc = props.location;
    const window = props.windowState

    function getClass(){
        if(loc === 'left'){
            if(window === 'left'){
                return 'hide'
            } else {
                return 'full'
            }
        } else {
            if(window === 'right'){
                return 'hide'
            } else {
                return 'full'
            }
        }
    }
  
    return (
      <div className={`split-wrapper ${getClass()}`}>
        {loc === 'left'?
            <LocationGraph 
            gw_number={props.gw_number}
            start={props.start}
            end={props.end}
            graphData={props.graphData}
            minSize={props.minSize}
            selectedInGraph={props.selectedInGraph}
            neighbourDegree={props.neighbourDegree}
            updateFiltering={props.filterEvents}
            actors={props.actors} 
            actorNames={props.actorNames} 
            eventTypeColours={props.eventTypeColours}
            linkTypeColours={props.linkTypeColours}
            isSelectable={true}
            highlightState={props.highlightState}
            updateGraphSelection={props.updateGraphSelection}
            labelSize={props.labelSize}
            linkWidth={props.linkWidth}
            ></LocationGraph>
            :
            <LocationMap 
            gw_number={props.gw_number}
            start={props.start}
            end={props.end}
            events={props.events}
            actors={props.actors}
            actorNames={props.actorNames}
            eventTypeColours={props.eventTypeColours}
            ></LocationMap>      
        }

      </div>
    );
  }