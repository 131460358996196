import './PeriodTimePicker.scss';
import { useState } from 'react';
import styles from '../../../styles/global_variables.scss';
// MUI components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// external libraries
import moment from 'moment';
import 'moment/locale/en-gb';

export default function PeriodTimePicker(props) {
    const [selectedDate, setSelectedDate] = useState(props.initialTime);

    function getMinDate(availableDays){
        var available = availableDays.map(d => moment(d));
        var min = moment.min(available);
        const format = 'DD-MM-YYYY';
        min = moment(min, format);
        return min;
    }

    function getMaxDate(availableDays){
        var available = availableDays.map(d => moment(d));
        var max = moment.max(available);
        const format = 'DD-MM-YYYY';
        max = moment(max, format);
        return max;
    }

    function handleDateChange(newDate){
        if(props.kind === 'start'){
            // set to start of time unit
            const startDate = newDate.startOf('day')
            setSelectedDate(startDate);
            props.handleDateChange(startDate);
        } else {
            // set to end of time untit
            const endDate = newDate.endOf('day')
            setSelectedDate(endDate);
            props.handleDateChange(endDate);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DatePicker
            openTo={'day'}
            value={selectedDate}
            onChange={(newDate) => handleDateChange(newDate)}
            minDate={getMinDate(props.availableDays)}
            maxDate={getMaxDate(props.availableDays)}
            views={['year', 'month', 'day']}
            className='date-picker-input'
            slotProps={{
                textField: {
                    variant: "filled",
                    sx: {
                        '& .MuiInputBase-input':{
                            padding: 0,
                            paddingLeft: '5px',
                            fontSize: '10pt',
                            color: styles.textPrimary
                        }
                        }     
                    },
            }}
            />
        </LocalizationProvider>
    );
}