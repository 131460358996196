import './EventsCard.scss';
import {useState, useEffect} from 'react';
import styles from '../../styles/global_variables.scss';
// API functions
import {getReportsByFilters} from '../../utils/queryBackend';
// MUI components
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ActorCard from '../ActorCard/ActorCard';
import InfoIcon from '@mui/icons-material/Info';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SquareIcon from '@mui/icons-material/Square';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
//components
import TypeIcon from '../TypeIcon/TypeIcon';
// utils
import interactionToSVG from '../../utils/interactionToSVG';
import interCodeMapping from '../../utils/interCodeMapping';
import eventTypeToText from '../../utils/eventTypeToText';
// external libraries
import _ from 'lodash';
import moment from 'moment';

export default function EventsCard(props) {
    const [reports, setReports] = useState([]);
    const [fullReports, setFullReports] = useState([]);
    const [openReportID, setOpenReportID] = useState(null);
    const [openActorCard, setOpenActorCard] = useState(false);
    const [selectedActor, setSelectedActor] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [allRelatedActors, setAllRelatedActors] = useState([])
    const [selectedActors, setSelectedActors] = useState([]);
    const [colourSettings, setColourSettings] = useState({})
    const [eventIds, setEventIds] = useState([]);

    const selectPlaceholderText = 'Actor'

    function selectFilter(code){
        const newFilter = _.cloneDeep(selectedFilter);
        const idx = selectedFilter.indexOf(code);
        if(idx !== -1){
            newFilter.splice(idx, 1)
        } else {
            newFilter.push(code)
        }
        setSelectedFilter(newFilter)
        queryReportById(eventIds, newFilter, selectedActors)
    }

    function updateReportIds(){

        var allIds

        if(props.link){
            allIds = props.link.reports;
        } else {
            const actor = props.mainActors[0]
            allIds = actor['eventReportIds']
        }
        
        allIds = _.uniq(allIds)
        setEventIds(allIds);
        queryReportById(allIds, selectedFilter, selectedActors)
    }

    useEffect(() => {
        setColourSettings(props.eventTypeColours)
    }, [props.eventTypeColours])

    useEffect(() =>{
        var collabs = props.mainActors.reduce((ac, actor) => ac.concat(Object.keys(actor['collaborations'])), [])
        var oppos = props.mainActors.reduce((ac, actor) => ac.concat(Object.keys(actor['oppositions'])), [])
        var related = _.uniq(collabs.concat(oppos))
        related = related.sort()

        const mainActorIds = props.mainActors.map(actor => related.indexOf(actor.getName()))

        mainActorIds.forEach(idx => related.splice(idx, 1))
        setAllRelatedActors(related)
        updateReportIds()
    }, [...props.mainActors])

    function queryReportById(ids, selectedFilter, selectedActors){
        var start = ''
        var end = ''
        if (props.start && props.end){
            start = props.start
            end = props.end
        }
        
        if(props.gw_number){
            // period data
            getReportsByFilters(`?mainActors=${props.mainActors.map(actor => {return actor.getName()})}&start=${start}&end=${end}&eventTypes=${selectedFilter}&actorNames=${selectedActors}&gw_number=${props.gw_number}`).then((responseData) => {
                setReports(responseData)
            })
            // full data
            getReportsByFilters(`?mainActors=${props.mainActors.map(actor => {return actor.getName()})}&start=${''}&end=${''}&eventTypes=${selectedFilter}&actorNames=${selectedActors}&gw_number=${props.gw_number}`).then((responseData) => {
                if(responseData){
                    setFullReports(responseData);
                }    
            })
        }
    }

    function controlOpenReportID(clickedID){
        if(openReportID === clickedID){
            // close the info if it is already open
            setOpenReportID(null);
        }  else {
            // open the info
            setOpenReportID(clickedID);
        }
    }

    function showActorCard(actor){
        actor = actor.trim()
        setSelectedActor(actor)
        setOpenActorCard(true)
    }

    function hideActorCard(){
        setOpenActorCard(false);
    };

    function getActor(name){
        const found_by_name = props.actors.find(actor => (actor.getName() === name));
        if(!found_by_name){
            return null
        }
        else{
            return found_by_name
        }
    }

    function handleActorSelection(event){
        const newSelection = event.target.value
        setSelectedActors(newSelection)
        queryReportById(eventIds, selectedFilter, newSelection)
    }

    return (
        <div className='events-card'>
            {getActor(selectedActor)? 
            <ActorCard
            gw_number={props.gw_number}
            open={openActorCard}
            onClose={hideActorCard}
            actor={getActor(selectedActor)}
            actorName={selectedActor}
            start={props.start}
            end={props.end}
            actors={props.actors}
            fullPeriod={false}
            eventTypeColours={props.eventTypeColours}
            >
            </ActorCard>
            :null}
            <div className='events-card-wrapper'>
                <div className='events-card-filter'>
                <AppBar position='static' sx={{backgroundColor: styles.textWhite }} className='event-type-wrapper' elevation={0}>
                    <Toolbar variant="dense" className='event-type-toolbar'>
                        {Object.keys(eventTypeToText).map((code) => {
                            return(
                                <Chip 
                                key={'filter-type-' + code}
                                label={eventTypeToText[code]}
                                onClick={() => {selectFilter(code)}}
                                sx={{
                                    paddingX: '5px', 
                                    backgroundColor: (selectedFilter.includes(code)? styles.primaryDark:styles.colorWhite), 
                                    fontSize: '0.8rem',
                                }}
                                color='primary'
                                icon={
                                    <SquareIcon
                                    key={'filter-type-icon-' + code}
                                    sx={{ "&&": { color: colourSettings[code] }}}
                                    fontSize='small'
                                    ></SquareIcon>}
                                >
                                </Chip>
                            )
                        })}
                        <Select
                        className='name-setting-elem'
                        id="name-select"
                        value={selectedActors}
                        sx={{maxHeight: 30}}
                        size='small'
                        multiple
                        displayEmpty
                        onChange={handleActorSelection}
                        MenuProps = {{ sx: {height: '50%'}}}
                        renderValue={() => {
                            if(selectedActors?.length === 0){
                                return <Typography sx={{fontSize:'0.8rem', color: styles.neutralDark}}>{selectPlaceholderText}</Typography>
                            }
                            return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                <Chip sx={{maxHeight: 24, fontSize: '0.7rem'}} key='num-actors' label={selectedActors?.length + ' selected'} className="selection-chip" />
                            </Box>
                        )}}
                        >
                            <MenuItem disabled value="">
                                <Typography>{selectPlaceholderText}</Typography>
                            </MenuItem>
                            {allRelatedActors.map(item => 
                                (
                                <MenuItem 
                                size='small' 
                                value={item} 
                                key={'filter-events-' + item}
                                >
                                    <Checkbox checked={selectedActors.includes(item)} color='secondary'/>
                                    <ListItemText primary={item} />
                                </MenuItem>))}
                        </Select>
                        <div className='event-report-number-wrapper'>
                            <Typography 
                            fontSize='0.8rem'
                            sx={{ px: 1, color: 'primary.contrastText' }}
                            className='event-report-number'
                            >{(props.full? fullReports?.length : reports?.length)} events</Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                </div>
                <div className='interaction-type-legend'>
                    {Object.keys(interCodeMapping).map((code) => {
                    const numCode = Number(code)
                    if(numCode !== 0){
                    return(
                        <div 
                        className='interaction-legend-item'
                        key={'filter-type-' + numCode}>
                            <TypeIcon
                            type={numCode}
                            dim={14}
                            color={styles.neutralDark}
                            ></TypeIcon>
                            <Typography sx={{marginX: '4px', textAlign:'center', marginY:'1px'}} fontSize='0.6rem' >{interCodeMapping[numCode]}</Typography>
                        </div> 
                    )} else { return null; }
                    })}
                </div>
                <List className='events-card-list'>
                    {((props.full && fullReports?.length === 0) || (!props.full && reports?.length === 0))? 
                    (<div className='no-data-events'>
                        <Typography fontSize='1.5rem'> {'no data ' + (props.full? 'in full data set' : 'in selected period')+ ' for this filter configuration'} </Typography>
                    </div>
                    ) 
                    : null}
                    {(props.full? fullReports : reports).map((report, index) => (
                        <ListItem 
                            key={'events-info-' + report.id}
                            className='list-item-wrapper'
                            sx={{py: '3px'}}
                        >
                            <div key={report.id + '-item'} className={'events-item-wrapper' + ((openReportID === report.id)? ' open' : '')} onClick={() => controlOpenReportID(report.id)}>
                                <Typography className='event-item' fontWeight='300' sx={{mx: '5px'}}>{report.id}</Typography>
                                <Typography className='event-item' sx={{mx: '5px'}}>{moment(report.date_start).format('DD.MM.YY')} {(report.date_start === report.date_end)? '' : (' - ') + moment(report.date_end).format('DD.MM.YY')}</Typography>
                                <div className='event-item type'>
                                    <SvgIcon 
                                    sx={{ color: colourSettings[report.event_type]}}
                                    >
                                        <path d={interactionToSVG(report.interaction)} /> 
                                    </SvgIcon>
                                    <Typography sx={{mx: '5px'}}>
                                    {eventTypeToText[report.event_type]}
                                    </Typography>
                                </div>
                                <Typography className='event-item' sx={{mx: '5px', whiteSpace:'nowrap'}}>{report.admin1}, {report.admin2}, {report.location_name}</Typography>
                            </div>
                            {(openReportID === report.id)? 
                                <div className='events-all-info'>
                                    <div className='events-info notes'>
                                        <div className='events-info-group'>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Source </Typography>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>{report.source_reference} ({report.source_origin}, {report.source_date})</Typography>
                                            </div>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Content </Typography>
                                                <Typography sx={{mx: '10px', fontWeight:'bold'}}>{report.source_content}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='events-info actor'>
                                        <div className='events-info-group'>
                                            {report.side_a.split(',').map((actor, index) => (
                                                <div className='events-line'>
                                                    <Typography fontSize='0.8rem' sx={{mx: '10px'}}>{index === 0? 'Side A' : ''}</Typography>
                                                    <Typography sx={{mx: '10px'}}>{actor} ({interCodeMapping[Math.floor(report.interaction / 10) % 10]})</Typography>
                                                    <IconButton
                                                    onClick={() => showActorCard(actor)}
                                                    sx={{py: 0}}
                                                    >
                                                        <InfoIcon 
                                                        sx={{ color: styles.infoIconColor, fontSize:'1rem'}}
                                                        ></InfoIcon>
                                                    </IconButton>
                                                </div>
                                            ))}
                                            {report.side_b.split(',').map((actor, index) => (
                                                <div className='events-line'>
                                                    <Typography fontSize='0.8rem' sx={{mx: '10px'}}>{index === 0? 'Side B' : ''}</Typography>
                                                    <Typography sx={{mx: '10px'}}>{actor} ({interCodeMapping[report.interaction % 10]})</Typography>
                                                    <IconButton
                                                    onClick={() => showActorCard(actor)}
                                                    sx={{py: 0}}
                                                    >
                                                        <InfoIcon 
                                                        sx={{ color: styles.infoIconColor, fontSize:'1rem'}}
                                                        ></InfoIcon>
                                                    </IconButton>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='events-info interaction'>
                                        <div className='events-info-group'>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Event Type</Typography>
                                                <Typography sx={{mx: '10px'}}>{eventTypeToText[report.event_type]}</Typography>
                                            </div>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Fatalities</Typography>
                                                <Typography sx={{mx: '10px'}}>{report.fatalities_best}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='events-info location'>
                                        <div className='events-info-group'>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Location</Typography>
                                                <Typography sx={{mx: '10px'}}>{report.admin1}, {report.admin2},  {report.location_name}, {report.location_detail}, {report.country_name}</Typography>
                                            </div>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Coordinates</Typography>
                                                <Typography sx={{mx: '10px'}}>{report.latitude}, {report.longitude}  (lat., long.), precision {report.location_precision}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='events-info time'>
                                        <div className='events-info-group'>
                                            <div className='events-line'>
                                                <Typography fontSize='0.8rem' sx={{mx: '10px'}}>Event Date</Typography>
                                                <Typography sx={{mx: '10px'}}>{moment(report.date_start).format('DD.MM.YY')} {(report.date_start === report.date_end)? '' : (' - ') + moment(report.date_end).format('DD.MM.YY')}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
}