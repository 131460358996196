import './BackButton.scss';
import { useSearchParams } from "react-router-dom";
// MUI components
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    function handleButtonClick(){
        /**
         * When a user clicks the button, the current specific search parameter 
         * should be deleted and the view should route back to the previous view.
         */
        searchParams.delete(props.searchParamName);
        setSearchParams(searchParams)
        props.resetSearch()
    }

    return (
        <div className='back-button'>
            <Button
            color='secondary'
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={handleButtonClick}
            >{props.buttonText}</Button>
        </div>
    );
}