import './DisplaySwitch.scss';
import styles from '../../styles/global.scss';
import { useState } from 'react';
// MUI components
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function DisplaySwitch(props) {
    const [checked, setChecked] = useState(!!props.options.indexOf(props.selectedOption));

    function handleChange(event){
        setChecked(event.target.checked);
        const index = event.target.checked? 1:0
        props.handleSwitchChange(props.options[index])
      };

    return (
    <Stack direction="row" spacing={1} alignItems="center">
        <Typography
        fontSize={styles.fontXSmall}
        color={styles.textLight}
        >{props.options[0]}</Typography>
        <Switch 
        checked={checked}
        onChange={handleChange}
        color="secondary"
        />
        <Typography
        fontSize={styles.fontXSmall}
        color={styles.textLight}
        >{props.options[1]}</Typography>
    </Stack>
    );
}