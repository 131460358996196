const content = {
    'SITE_TITLE':           'PEACE OBSERVATORY',
    'MAIN_TAB':             'PEACE DATA',
    'EXPLORER_TAB':         'CONFLICT NETWORKS', 
    'VISUALISATIONS_TAB':   'VISUALISATIONS',
    'PODCASTS_TAB':         'PODCASTS', 
    'DOWNLOAD_TAB':         'DOWNLOAD',
    'ABOUT_TAB':            'ABOUT',
    'SEARCH_EMPTY':         'SEARCH',
    'SEARCH_EXPANDED':      'select a country',
    'BACK_TO_MAIN':         'WORLD VIEW',
    'BACK_TO_DETAIL':       'COUNTRY VIEW',
    'PLACEHOLDER':          'coming soon...',
    'CITATION_TEXT':        'Please use the following citation when using this data set:',
    'CITATION':             (
                            'Duursma, Allard, and Samantha Gamez. "Introducing the African Peace Processes (APP) Dataset: \
                            Negotiations and Mediation in Interstate, Intrastate, and Non-State Conflicts in Africa." Journal of Peace Research (2022).'
                            ),
    'EVENT_ACCESS_TEXT':    'The event data originates from the UCDP Georeferenced Event Dataset (GED), it can be accessed at ',
    'EVENT_ACCESS_LINK':    'https://ucdp.uu.se/downloads/',
}

export default content