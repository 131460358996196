import './DownloadView.scss';
import styles from '../../styles/global.scss';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// API functions
import {
    getAvailableDownloads, 
    getAvailableSummaries, 
    downloadMultipleFiles
} from '../../backend/queryBackend';
// predefined data
import content from '../../content/content';
// MUI components
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DownloadIcon from '@mui/icons-material/Download';
// components
import DownloadItem from '../DownloadItem/DownloadItem';

export default function DownloadView(props) {
    const [needFetching, setNeedFetching] = useState(false);
    const [availableDownloads, setAvailableDownloads] = useState();
    const [availableSummaries, setAvailableSummaries] = useState();
    const [selectedTypeFilter, setSelectedTypeFilter] = useState([]);
    const [textInput, setTextInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const optionsKey = 'filename'; // which property to use for filtering

    function handleTextInput(event){
        setTextInput(event.target.value)
    }

    function handleTypeFilterChange(event, newSelections){
        setSelectedTypeFilter(newSelections);
    }

    function handleDownloadAll(){
        // call API to download all files currently returned by filter
        const filenames = filteredData.map(item => {return item['filename'] + '.' + item['filetype']})
        downloadMultipleFiles(`?filenames=${filenames}`)
    }

    useEffect(() => {
        // prevents API call from being executed twice
        if (needFetching) return;
        setNeedFetching(true);
    });

    useEffect(() => {
        if (!needFetching) return;

        if(!availableDownloads){
            getAvailableDownloads(`?`).then((responseData) => {
                setAvailableDownloads(responseData) 
          })
        }
        if(!availableSummaries){
            getAvailableSummaries(`?`).then((responseData) => {
                setAvailableSummaries(responseData) 
                setFilteredData(responseData)
          })
        }
        
    }, [needFetching])

    useEffect(() => {
        /**
         * Whenever any of the input variables changes,
         * apply the filter to all summaries available for download
         */
        let filtered = availableSummaries;
        if(textInput && textInput.length > 0){
            // apply text filtering
            filtered = filtered.filter(item => item[optionsKey].toLowerCase().includes(textInput.toLowerCase()))
        }
        if(selectedTypeFilter && selectedTypeFilter.length> 0){
            // apply type filtering
            filtered = filtered.filter(item => selectedTypeFilter.includes(item[optionsKey].split('_')[0]))
        }
        setFilteredData(filtered)
    }, [availableSummaries, selectedTypeFilter, textInput])

    return (
        <div className="download-view">
            <div className='text-wrapper'>
                <div className='download-text'>
                    <Typography 
                    mb={2} 
                    fontSize={styles.fontText}
                    color={styles.textLight}
                    >
                        On this page, you can download the Peace Observatory Dataset, the codebook, and all analytical summaries of peace negotiations recorded in the dataset.
                    </Typography>
                    <Typography 
                    mb={1} 
                    color={styles.textLight}
                    fontSize={styles.fontText}
                    >
                        Please use the following citation when using the Peace Observatory Dataset:                
                    </Typography>
                    <Typography 
                    mb={10} 
                    color={styles.textLight}
                    fontSize={styles.fontText}
                    >
                        Allard Duursma, Marine Epiney, Owen Fraser, Samantha Gamez, Carina Diener & Orestes Leventis (2024) Introducing the Peace Observatory: A Global Dataset on Peace Negotiations in Interstate, Intrastate, and Non-State Conflicts.                
                    </Typography>
                </div>
                <div className='download-text'>
                    <Typography 
                    mb={2} 
                    color={styles.textLight}
                    fontSize={styles.fontText}
                    >
                        The African Peace Process (APP) Dataset (Version 2.0) can be downloaded at <Typography fontSize={styles.fontText} fontWeight='bold' component={Link} to={'https://www.allardduursma.com/publication/african-peace-processes-dataset/'} target="_blank" rel="noopener noreferrer">https://www.allardduursma.com/publication/african-peace-processes-dataset/</Typography>
                    </Typography>
                    <Typography 
                    mb={1} 
                    color={styles.textLight}
                    fontSize={styles.fontText}
                    >
                        Please use the following citation when using the APP data set:             
                    </Typography>
                    <Typography 
                    mb={1} 
                    color={styles.textLight}
                    fontSize={styles.fontText}>
                        Allard Duursma and Samantha Gamez (2022) Introducing the African Peace Processes (APP) Dataset: Negotiations and Mediation in Interstate, Intrastate, and Non-State Conflicts in Africa. <i fontStyle='italic'>Journal of Peace Research</i>.         
                    </Typography>
                </div>
            </div>
            <div className='downloads'>
                <Typography 
                fontSize={styles.fontSmall} 
                color={styles.textLight}
                >
                    Peace Processes Data
                </Typography>
                <div className='download-wrapper ppdata'>
                    <div className='download-items'>
                        {availableDownloads?.map(file => {
                        return (
                        <DownloadItem 
                        folder='base'
                        key={'file-download-' + file.filename}
                        item={file}></DownloadItem>
                        )})}
                    </div>
                </div>
                <Typography 
                fontSize={styles.fontSmall} 
                color={styles.textLight}
                >
                    Summaries of Peace Negotiations
                </Typography>
                <div className='summaries-wrapper'>
                    <div className='download-items bg'>
                        {filteredData?.map(file => {
                        return (
                        <DownloadItem 
                        folder='summaries'
                        key={'file-download-' + file.filename}
                        item={file}></DownloadItem>
                        )})}
                    </div>
                    <div className='summaries-filter'>    
                        <Box sx={{ minWidth: styles.inputFilterTextWidth, marginY: '5px' }}>
                            <TextField
                            id="text-search" 
                            label="Search" 
                            variant="outlined"
                            value={textInput}
                            onChange={handleTextInput}
                            size="small"
                            sx={{ width: '100%'}}
                            className='download-search'
                            InputProps={{
                                className: 'input',
                            }}
                            >
                            </TextField>
                        </Box>
                        <Box sx={{ marginY: '5px' }}>
                            <ToggleButtonGroup
                            value={selectedTypeFilter}
                            onChange={handleTypeFilterChange}
                            aria-label="type filter"
                            className='summaries-buttons'
                            >
                                <ToggleButton value="inter" aria-label="inter" className='bg-button'>
                                    Inter
                                </ToggleButton>
                                <ToggleButton value="intra" aria-label="intra" className='bg-button'>
                                    Intra
                                </ToggleButton>
                                <ToggleButton value="nonstate" aria-label="nonstate" className='bg-button'>
                                    Nonstate
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Button
                        sx={{ marginY: '5px' }}
                        color='secondary'
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        onClick={handleDownloadAll}
                        >download {filteredData?.length}/{availableSummaries?.length} files (zip)
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}