// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".location-map-wrapper {\n  height: 100%;\n  max-height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n\n.location-map-view {\n  height: 100%;\n  max-height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n\n.location-selections-wrapper {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n\n.map-container {\n  height: 100%;\n  max-height: 100%;\n  width: 100%;\n  display: flex;\n}\n\n.map-svg {\n  width: 100%;\n  height: 100%;\n  max-height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/ConflictExplorer/components/LocationMap/LocationMap.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AA2BJ;;AAxBA;EACI,YAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AA2BJ;;AAxBA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AA2BJ;;AAxBA;EACI,YAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;AA2BJ;;AAxBA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AA2BJ","sourcesContent":["@import '../../styles/global_variables';\n\n.location-map-wrapper{\n    height: 100%;\n    max-height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n}\n\n.location-map-view{\n    height: 100%;\n    max-height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n}\n\n.location-selections-wrapper{\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n}\n\n.map-container{\n    height: 100%;\n    max-height: 100%;\n    width: 100%;\n    display: flex;\n}\n\n.map-svg{\n    width: 100%;\n    height: 100%;\n    max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#fafafa",
	"primaryLight": "#f0eded",
	"primaryDark": "#c7c7c7",
	"secondaryColor": "#bdbdbd",
	"secondaryLight": "#d7d7d7",
	"secondaryDark": "#8d8d8d",
	"textPrimary": "#4C4C4C",
	"textSecondary": "#000000",
	"neutralColor": "#c7c7c7",
	"neutralLight": "#e0e0e0",
	"neutralDark": "#aeaeae",
	"textNeutral": "#000000",
	"textWhite": "#ffffff",
	"mainBorderRadius": "4px",
	"mapPopupWidth": "500px",
	"infoIconColor": "#abcdef",
	"typeIconColor": "#000000",
	"multipleAppearanceColor": "#ffff00",
	"highlightColor": "#fea500",
	"mapPopUpInteraction": "50px",
	"linkHighlight": "#999999",
	"helpMain": "#b2dfdb",
	"helpLight": "#e5ffff",
	"helpDark": "#82ada9",
	"timelineHighlight": "#000000"
};
export default ___CSS_LOADER_EXPORT___;
