import './LocationGraph.scss';
import styles from '../../styles/global_variables.scss';
import { useEffect, useRef, useState } from 'react';
// components
import GraphLegend from '../GraphLegend/GraphLegend';
import LinkCard from '../LinkCard/LinkCard';
import ActorCard from '../ActorCard/ActorCard';
// utils
import interCodeToSVG from '../../utils/interCodeToSVG';
import wrapRelText from '../../utils/wrapRelText';
// external libraries
import * as d3 from "d3";
import _ from 'lodash';

export default function LocationGraph(props) {
    const wrapperRef = useRef();
    const svgRef = useRef();
    const [forceCenter, setForceCenter] = useState([250, 350]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [colourSettings, setColourSettings] = useState({});
    const [linkColours, setLinkColours] = useState({});
    const [openCard, setOpenCard] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    const [selectedActor1, setSelectedActor1] = useState(null);
    const [selectedActor2, setSelectedActor2] = useState(null);
    const [linkSelection, setLinkSelection] = useState(null);
    const [selectedActor, setSelectedActor] = useState('');
    const [openActorCard, setOpenActorCard] = useState(false);

    useEffect(() => {
        setColourSettings(props.eventTypeColours)
    }, [props.eventTypeColours])

    useEffect(() => {
        setLinkColours(props.linkTypeColours)
    }, [props.linkTypeColours])

    useEffect(() => {
        setSelectedNodes(props.selectedInGraph);
    }, [JSON.stringify(props.selectedInGraph)]) // stringify to be able to use array as dependency

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const graph_height = entries[0].borderBoxSize[0].blockSize;
            const graph_width= entries[0].borderBoxSize[0].inlineSize;
            setForceCenter([graph_width / 2, graph_height / 2]);
        })
        resizeObserver.observe(wrapperRef.current);
    })

    function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
        // https://stackoverflow.com/questions/5294955/how-to-scale-down-a-range-of-numbers-with-a-known-min-and-max-value
        return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
    }

    function getLinkWidth(d){
        var maxLinkWidth = props.linkWidth;
        var minLinkWidth = 3;
        var maxLinkSize = props.graphData.maxLink;
        const linkCount = d['count'];
        var scaled = scaleBetween(linkCount, minLinkWidth, maxLinkWidth, 1, maxLinkSize);
        return scaled 
    }

    function getLinkColour(d){
        if(linkColours){
            if(d['linkType'] === 0){
                return linkColours['cooperation']['colour']
            } else if(d['linkType'] === 1){
                return linkColours['opposition']['colour']
            } else {
                return linkColours['other']['colour']
            }
        } else {
            return '#000000'
        }
        
    }

    function getLinkStyle(d){
        if(linkColours){
            if(d['linkType'] === 0){
                return linkColours['cooperation']['stroke']
            } else if(d['linkType'] === 1){
                return linkColours['opposition']['stroke']
            } else {
                return linkColours['other']['stroke']
            }
        } else {
            return ''
        }
    }

    function getLinkOpacity(d){
        if(linkSelection){
            if(d['linkType'] === 0){
                return linkSelection['cooperation']? 1:0
            } else if(d['linkType'] === 1){
                return linkSelection['opposition']? 1:0
            } else {
                return linkSelection['other']? 1:0
            }
        } else {
            return 1
        }
    }

    function getNodeColour(d){
        if( selectedNodes.includes(d['actor_id']) ){
            return styles.highlightColor;
        } else return styles.primaryLight;
    }

    function mouseoverLink(event, d){
        d3.select('#graph-link-' + d['source'].actor_id + '-' + d['target'].actor_id)
            .attr("stroke", function(d) { return styles.linkHighlight })
    }

    function mousemoveLink(event, d){
        d3.select('#graph-link-' + d['source'].actor_id + '-' + d['target'].actor_id)
            .attr("stroke", function(d) { return styles.linkHighlight })
    }

    function mouseleaveLink(event, d){
        d3.select('#graph-link-' + d['source'].actor_id + '-' + d['target'].actor_id)
            .attr("stroke", function(d) { return getLinkColour(d) })
    }

    function handleClickNode(event, d){
        if(props.isSelectable){
            var selected = _.cloneDeep(selectedNodes)
            if (selected.includes(d.actor_id)){ 
                // get index of element
                const idx = selected.indexOf(d.actor_id);
                // remove item from selected 
                selected.splice(idx, 1);
            } else {
                // add item to selected
                selected.push(d.actor_id);
                if(props.neighbourDegree === 1){
                    const relLinks = props.graphData.links.filter((link) => {return ((link.source === d) || (link.target === d))});
                    const allIds = relLinks.map(link => {return [link.source.actor_id, link.target.actor_id]});
                    const combined = selected.concat(allIds);
                    selected = _.uniq(combined.flat());
                }
            }
            
            setSelectedNodes(selected);
            props.updateFiltering(selected);
            props.updateGraphSelection(selected);
        } else {
            // open actor card
            showActorCard(d.actor_name)
        }
    }

    function handleClickLink(link){
        if(linkSelection){
            if(link['linkType'] === 0){
                if(linkSelection['cooperation']){
                    showEventCard(link);
                }
            } else if(link['linkType'] === 1){
                if(linkSelection['opposition']){
                    showEventCard(link);
                }
            } else {
                if(linkSelection['other']){
                    showEventCard(link);
                }
            }
        } else {
            showEventCard(link);
        }
    }

    function getLinkCursorType(link){
        if(linkSelection){
            if(link['linkType'] === 0){
                if(linkSelection['cooperation']){
                    return 'pointer';
                } else {
                    return 'default';
                }
            } else if(link['linkType'] === 1){
                if(linkSelection['opposition']){
                    return 'pointer';
                } else {
                    return 'default';
                }
            } else {
                if(linkSelection['other']){
                    return 'pointer';
                } else {
                    return 'default';
                }
            }
        } else {
            return 'pointer';
        }
    }

    useEffect(()=>{
        if(props.isSelectable){
            props.updateFiltering(selectedNodes);
        } 
    }, [props.minSize])

    useEffect(() => {

        // after example from https://observablehq.com/@d3/force-directed-graph

        const data = props.graphData;
        var svg = d3.select(svgRef.current)

        if(props.eventTypeColours && props.linkTypeColours){
            
            const highlightWidth= 5;

            var containerSize = 45
            var innerRadius = containerSize / 6;
            var outerRadius = containerSize / 2 - highlightWidth;
            var scale = (2* innerRadius) / (Math.SQRT2 * 24);
            var transAmount = (containerSize - (scale * 24)) / 2
            var eventsTransAmount = containerSize / 2

            // filter nodes and links to only contain items above threshold (>= minSize)
            const filteredNodes = data.nodes.filter(node => (node['appearance'] >= props.minSize))
            const filteredNodesIds = filteredNodes.map(node => {return node['actor_id']});

            const filteredLinks = data.links.filter(link => {

                const withIDs = filteredNodesIds.includes(link['source']) && filteredNodesIds.includes(link['target']);
                const withNodes = filteredNodes.includes(link['source']) && filteredNodes.includes(link['target']);

                const shouldKeep = withIDs || withNodes;

                return shouldKeep;
            })

            // prevent graph from being drawn multiple times
            svg.selectAll("circle").remove();
            svg.selectAll("g").remove();
            svg.selectAll("line").remove();
            svg.selectAll('text').remove();
            svg.selectAll('svg').remove();

            // Construct the forces.
            const forceNode = d3.forceManyBody().strength(-300).distanceMax(400);
            const forceLink = d3.forceLink(filteredLinks).id(function(d) { return d.actor_id; }).distance(200);

            const simulation = d3.forceSimulation(filteredNodes)
                .force("link", forceLink)
                .force("charge", forceNode)
                .force("center",  d3.forceCenter().x(forceCenter[0]).y(forceCenter[1]))
                .force('collision', d3.forceCollide().radius(containerSize/2))
                .on("tick", ticked);

            simulation.force("forceX", d3.forceX(forceCenter[0]).strength(0.1))
            .force("forceY", d3.forceY(forceCenter[1]).strength(0.1));
  
            // Initialize the links
            var link = svg.append("g")
            .selectAll("line")
            .data(filteredLinks)
            .join("line")
                .attr('id', d => 'graph-link-' + d['source'].actor_id + '-' + d['target'].actor_id)
                .on("mouseover", mouseoverLink)
                .on("mousemove", mousemoveLink)
                .on("mouseleave", mouseleaveLink)
                .style('cursor', d => getLinkCursorType(d))
                .on('click', (e,d) => handleClickLink(d))
                .style('opacity', d => getLinkOpacity(d))
                .attr("stroke", function(d) { return getLinkColour(d) })
                .attr("stroke-width", function(d) { return getLinkWidth(d) })
                .attr("stroke-dasharray", function(d) { return getLinkStyle(d) });

            function getIconPathByID(id){
                var found = props.actors.find(actor => (actor.id === id))
                if(found){
                    return interCodeToSVG[found.actorType].path
                } else {
                    return ''
                }
            }

            function getEventTypeSummaryById(id){
                var found = props.actors.find(actor => (actor.id === id))
                if(found){
                    return found.eventTypeSummary
                } else {
                    console.warn('Actor Not Found', id)
                    return {}
                }
            }
            
            // Initialize the nodes
            var node = svg.append('g')
            .style('cursor', 'pointer')
            .selectAll("g")
            .data(filteredNodes)
            .join("g") 
            .attr('id', 'nodesContainer')
                .append('svg')
                .on('click', handleClickNode)
                .call(drag(simulation));
   
            var nodeItem = node.append('g')
            
            nodeItem.append("text")
            .text(d => d.actor_name)
            .attr("x", 2 * outerRadius + 10)
            .attr("y", outerRadius)
            .style("font-size", props.labelSize)
            .call(wrapRelText, 100)

            const eventTypes = props.actors[0]? props.actors[0].eventTypeSummary : {}

            // add background circles for highlight
            function getHighlight(d){
                if(props.highlightState.isHighlighted && props.highlightState.actorName === d.actor_name){
                    return 1
                } else {
                    return 0
                }
            }
            nodeItem.append('g')
            .append('circle')
            .attr('r', outerRadius + highlightWidth - 1)
            .attr("transform", `translate(${eventsTransAmount},${eventsTransAmount})`)
            .attr('fill', styles.multipleAppearanceColor)
            .attr('stroke', styles.secondaryDark)
            .style('opacity', d=>getHighlight(d))

            // add background circles
            nodeItem.append('g')
            .append('circle')
            .attr('r', outerRadius)
            .attr('class', 'graph-node')
            .attr("transform", `translate(${eventsTransAmount},${eventsTransAmount})`)
            .attr('fill', d => getNodeColour(d))

            // X scale
            var x = d3.scaleBand()
            .range([0, - 2 * Math.PI])    // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
            .align(0)               
            .domain( Object.keys(eventTypes))

            // Y scale
            var y = d3.scaleRadial()
            .range([innerRadius, outerRadius])   // Domain will be define later.
            .domain([0, 1]); // Domain of Y is from 0 to the max seen in the data

            nodeItem.append('g')
            .attr("transform", `translate(${transAmount},${transAmount})scale(${scale})`)
            .append('path')
                .attr('d', d => getIconPathByID(d['actor_id']))
                .attr('fill', d => '#000000')
                .attr('stroke', d => '#000000')

            function getBars(id){
                var summary = getEventTypeSummaryById(id)
                var bars = []
                Object.keys(summary).forEach(name => {
                    var obj = {
                        key: name,
                        id: id, 
                        val: summary[name]
                    }
                    bars.push(obj)
                })
                return bars
            }

            nodeItem.append('g')
                .attr("transform", `translate(${eventsTransAmount},${eventsTransAmount})`)
                .selectAll('path')
                .data(d =>  getBars(d['actor_id']))
                .join('path')
                    .attr("fill", d => {if(colourSettings){return colourSettings[d['key']]}})
                    .attr("d", d3.arc()     // imagine your doing a part of a donut plot
                        .innerRadius(innerRadius)
                        .outerRadius(d => y(d['val']))
                        .startAngle(d => x(d['key']))
                        .endAngle(d => x(d['key']) + x.bandwidth())
                        .padAngle(0.01)
                        .padRadius(innerRadius))

            setTimeout(function(){
                simulation.stop();
            }, 2000)

            // This function is run at each iteration of the force algorithm, updating the nodes position.
            function ticked() {
            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node
                .attr("x", d => d.x - containerSize/2)
                .attr("y", d => d.y - containerSize/2);
    
            }

            // zooming 
            // resource: https://www.d3indepth.com/zoom-and-pan/
            function handleZoom(event) {
                // apply transform to the chart
                d3.selectAll('svg g line')
                    .attr('transform', event.transform);

                d3.selectAll('#nodesContainer')
                    .attr('transform', event.transform);                
            }


            let zoom = d3.zoom()
                .on('zoom', handleZoom);

            d3.selectAll('svg')
                .call(zoom);

            function drag(simulation) {    
                function dragstarted(event) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                event.subject.fx = event.subject.x;
                event.subject.fy = event.subject.y;
                }
                
                function dragged(event) {
                event.subject.fx = event.x;
                event.subject.fy = event.y;
                }
                
                function dragended(event) {
                if (!event.active) simulation.alphaTarget(0);
                // NOTE: fx and fy define fixed positions of the node, so if we do not set them to null, 
                // the dragged circle stays where it was dragged to
                // event.subject.fy = null;
                // event.subject.fx = null; 
                }
                
                return d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended);
            }
        }
    }, [props, colourSettings, linkColours, linkSelection]);

    function showEventCard(link){
        setSelectedLink(link);
        setSelectedActor1(findActor(0, link));
        setSelectedActor2(findActor(1, link));
        setOpenCard(true);
    }

    function hideEventCard(){
        setOpenCard(false);
    };

    function setLinkFilter(newFilter){
        setLinkSelection(newFilter);
    }

    function findActor(type, link){
        var found;
        if(type === 0){
            found = props.actors.find(actor => (actor.id === link['source'].actor_id))
        } else {
            found = props.actors.find(actor => (actor.id === link['target'].actor_id))
        }
        return found
    }

    function getActor(name){
        const found_by_name = props.actors.find(actor => (actor.getName() === name));
        if(!found_by_name){
            return null
        }
        else{
            return found_by_name
        }
    }

    function showActorCard(actor){
        actor = actor.trim()
        setSelectedActor(actor)
        setOpenActorCard(true)
    }

    function hideActorCard(){
        setOpenActorCard(false);
    };
 
    return(
        <div className='location-graph-wrapper' 
        id='graph_vis' 
        ref={wrapperRef}
        >
            {getActor(selectedActor)? 
            <ActorCard
            gw_number={props.gw_number}
            open={openActorCard}
            onClose={hideActorCard}
            actor={getActor(selectedActor)}
            actorName={selectedActor}
            start={props.start}
            end={props.end}
            actors={props.actors}
            fullPeriod={false}
            eventTypeColours={props.eventTypeColours}
            >
            </ActorCard>
            :null}
            {<LinkCard
            gw_number={props.gw_number}
            actor1={selectedActor1}
            actor2={selectedActor2}
            actors={props.actors}
            open={openCard}
            onClose={hideEventCard}
            start={props.start}
            end={props.end}
            fullPeriod={false}
            eventTypeColours={props.eventTypeColours}
            link={selectedLink}
            linkTypeColours={props.linkTypeColours}
            ></LinkCard>}
            <svg className='location-graph-view'  ref={svgRef}></svg>
            <GraphLegend
            linkTypeColours={props.linkTypeColours}
            setLinkFilter={setLinkFilter}
            ></GraphLegend>
        </div>
    );
}