import './AppTitle.scss';
import { Link } from "react-router-dom";
// predefined data
import logo from '../../../assets/logo.svg'
import content from '../../../content/content';
// MUI components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function AppTitle(props) {
  return (
    <Box className="title-wrapper" component={Link} to={'/'} sx={{textDecoration: 'none'}}>
      <div className='title-logo'>
        <img src={logo} className="svg-logo" alt="peace observatory logo" />
      </div>
      <Typography className='title-text'>{content.SITE_TITLE}</Typography>
    </Box>
  );
}